import React, {useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {Provider} from "react-redux";
import styled from 'styled-components';
import {
    Application,
    ThemeProvider,
    TopMenu,
    SideMenu,
    useNotification,
    withNotificationProvider
} from '@centrepointmedia/react-ui';
import {AuthPage, AuthProvider} from '@centrepointmedia/react-auth';
import {AppManager} from "./apps";
import store from './data';
import { Store } from './data/store';
import { customTheme } from './core/theme';

const Logo = styled.img`
    width: 40px;
    margin-left: 26px;
    margin-top: 4px;
`;

const appManager = AppManager();

function App() {
    const [page, setPage] = useState('Home');
    const history = useHistory();
    const location = useLocation();
    const notificationManager = useNotification();
    const onRouteChange = (route) => {
        history.push(route);
    }

    appManager.updatePathName(location.pathname);

    const onLoginError = () => 
        notificationManager.add({message: "There has been an error logging you in", icon: "error"});
  return (
    <Provider store={store}>
        <ThemeProvider customTheme={customTheme} selectedTheme={'light'} render={(theme) => (
            <AuthProvider theme={theme} render={(user, signOut) =>
                <Application customTheme={customTheme} selectedTheme={'dark'}
                    renderNav={(theme, context) => <TopMenu theme={theme} title={"Content Editor"} onSignOut={signOut} height={50} renderTitle={() => <Logo src="/logo-small.svg"></Logo>} />}
                    renderSidePanel={(theme, context) => <SideMenu canExpand={false} onClick={(item) => onRouteChange(item.route)} items={appManager.menuItems()} theme={theme} />}
                    renderMain={(theme, context) => {
                        return (
                            <>
                                {appManager.renderRoute({theme, context})}
                            </>
                        )
                    }} />
            } unauthorised={(login) => <AuthPage theme={theme} login={login} />} 
            onLoginError={onLoginError} />
        )} />
    </Provider>
  );
}

export default withNotificationProvider(App);
