const Formats = [
    "Cinema",
    "Netflix",
    "Disney+",
    "NowTV",
    "Film4",
    "TV",
    "Amazon Prime",
    "Apple TV+",
    "Britbox"
].map((format, idx) => ({text: format, key: idx+1}));

export {Formats};