import React, {useState} from 'react';
import styled from 'styled-components';
import { Button, SlidingModal, Label, FaIcon } from '@centrepointmedia/react-ui';
import { ArticleEdit } from '../data/use.articles';
import { AdvertiserPicker } from '../../../advertisers/views/advertiser.picker';

const ValueWrapper = styled.div`
    margin:0 10px;
    display: inline-flex;
`;

const AdvertiserName = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.textColor};
    font-style: italic;
    i {
        margin-left: 10px;
        cursor: pointer;
        margin-top: 2px;
    }
`;
const AdvertiserTabBody = ({theme}) => {
    const [state, setState] = useState({pickingAdvertiser: false});
    const controller = ArticleEdit.useContainer();
    const {model} = controller;

    const onAddAdvertiser = () => setState(s => ({...s, pickingAdvertiser: true}));
    const onCancelAddAdvertiser = () => setState(s => ({...s, pickingAdvertiser: false}));
    const onSelectAdvertiser = (advertiser) => {
        console.log(advertiser);
        controller.update({advertiser: advertiser._id});
        onCancelAddAdvertiser();
    }

    const onRemoveAdvertiser = () => controller.update({advertiser: null});

    console.log(model);
    return <>
        <Label text="Advertiser:" theme={theme} />
        <ValueWrapper>
            {model.advertiser ? <AdvertiserName theme={theme}>
                    {model.advertiser.name && <><strong>{model.advertiser.name}:</strong> ({model.advertiser.advertiser.name}) </>} 
                    {!model.advertiser.name && <>Advertiser updated, please save article</>}
                    <FaIcon onClick={onRemoveAdvertiser} name="times" sizeUnit="rem" fontSize={1} theme={theme}/>
                </AdvertiserName> : 
                <Button theme={theme} text="Add" type="primary" iconName="plus" onClick={onAddAdvertiser} />}
        </ValueWrapper>
        <SlidingModal size="medium" theme={theme} onOk={onCancelAddAdvertiser} visible={state.pickingAdvertiser}>
            <AdvertiserPicker theme={theme} onSelect={onSelectAdvertiser} selected={model.advertiser} />
        </SlidingModal>
    </>
}

const AdvertiserTab = {
    settings: {
        name: 'Advertiser',
        icon: 'ad'
    },
    component: AdvertiserTabBody
}

export {AdvertiserTab};