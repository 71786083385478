import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {
    Panel,
    Toolbar, ToolbarItem, ToolbarItemInput,
    Table, TableColumn, TableColumnMultiLine,
    TableColumnSubtitle, TableColumnTitle, FaIcon, DropDown, Pager, Loader, SlidingModal, SideMenu, TextInput, Breadcrumb

} from "@centrepointmedia/react-ui";
import {AdvertiserEditView} from "./advertiser.edit";
import { Advertiser } from "../data/use.advertiser";
import { Advertisers } from "../data/use.advertisers";
import {useHistory, useLocation} from 'react-router-dom';

const TableContainer = styled.div`
    margin-top: 20px;
`;

const AccessoryWrapper = styled.div`
    text-align: right;
`;

const NoResults = styled.div`
    margin-top: 10px;
    padding: 20px;
    border-radius: 6px;
    background-color: ${props => props.background};

    p {
        color: ${props => props.color};
        font-size: .875rem;
    }
`;

const BreadcrumbWrapper = styled.div`
    padding: 5px 0;
    margin-bottom: 8px;
`;

const Container = styled.div`
    display: flex;
    margin: -25px -5px;
    height: calc(100vh - 51px);
    overflow: hidden;
`;

const INITIAL_STATE = {
    addingNewAdvertiser: false,
    editingAdvertiser: false,
    rootMenu: [],
    createNewSection: false,
    newSection: "",
    selectedRoot: "",
    addNewCategory: false,
    newCategory: "",
    addSubCategory: false,
    subCategory: "",
    selectedCategory: "",
    route: [],
    breadcrumbs: []
};
const AdvertisersView = ({theme}) => {
    const [state, setState] = useState({...INITIAL_STATE});
    const controller = Advertiser.useContainer();
    const listing = Advertisers.useContainer();

    useEffect(() => {
        listing.load();
    }, []);

    const onAddNewItem = () => setState(s => ({...s, addingNewAdvertiser: true}));
    const onCancelNewItem = () => setState(s => ({...s, addingNewAdvertiser: false, editingAdvertiser: false}));
    const onSaveNewItem = () => {
        controller.save().then(listing.load);
        setState(s => ({...s, editingAdvertiser: false, addingNewAdvertiser: false}));
    }

    const onEditContent = (row, index) => {
        controller.load(row);
        setState(s => ({...s, editingAdvertiser: true}));
    }

    const getAdvertiserDetails = (advertiser) => {
        const details = [advertiser.contactName, advertiser.contactEmail]
        .filter(d => d && d.length > 0).join(', ');
        console.log(details);
        return details.length > 0 ? details : "No advertiser";
    }

    const renderTable = () => {
        return listing.advertisers.length === 0 ? (
            <NoResults background={theme.color5}>
                <p color={theme.textColor}>No results could be found matching your query</p>
            </NoResults>
        ) : (
            <TableContainer>
                <Table theme={theme} name="articles" items={listing.advertisers} onRenderRow={(a, index) => (
                    <TableColumnMultiLine key={`row-${index}`}>
                        <TableColumnTitle>{a.name}</TableColumnTitle>
                        <TableColumnSubtitle>{getAdvertiserDetails(a)}</TableColumnSubtitle>
                    </TableColumnMultiLine>
                    
                )} onRenderAccessory={(row, index) => (
                    <TableColumn>
                        <AccessoryWrapper>
                            <FaIcon onClick={() => onEditContent(row, index)} name="pencil" fontSize={1} sizeUnit={'rem'} style='light' label="Edit" />
                        </AccessoryWrapper>
                    </TableColumn>
                )}  onRenderColumnHead={(row, index) => "Advertiser"}
                renderHeaders={true} columnCount={1}/>
            </TableContainer>
        );
    }
    return (
        <Container>
            <Panel theme={theme} body={() => (
            <>
                <Toolbar>
                    <ToolbarItem iconName={"plus"} onClick={onAddNewItem}>Add Advertiser</ToolbarItem>
                </Toolbar>
                {renderTable()}
                <SlidingModal theme={theme}  overflow={"hidden"} visible={state.addingNewAdvertiser} onOk={onSaveNewItem} onCancel={onCancelNewItem}>
                    <AdvertiserEditView theme={theme} />
                </SlidingModal>
                <SlidingModal theme={theme}  overflow={"hidden"} visible={state.editingAdvertiser} onOk={onSaveNewItem} onCancel={onCancelNewItem}>
                    <AdvertiserEditView theme={theme} />
                </SlidingModal>
                {listing.isLoading && <Loader type={"bar"} theme={theme} message="Please wait..."/>}
            </>
        )} />
        </Container>
    )
};

export {AdvertisersView};