import React, {Fragment, useState} from "react";
import { ControlsView } from "./../components/controls.component";

const heroDescription = "Use this section to add a hero to the article."
const contentDescription = "Use this section to add content before the recipe";
const ingredientsDescription = "Add the recipe ingredients here";
const methodDescription = "Add the recipe method here";

const ContentTabBody = ({theme}) => {
    return <>
    <ControlsView
        theme={theme}
        propKey="hero"
        label="Hero"
        max={1}
        filter={['image', 'video']}
        description={heroDescription}/>

    <ControlsView
        theme={theme}
        propKey="content"
        label="Content"
        max={1}
        filter={['paragraph', 'quote', 'image', 'video']}
        description={contentDescription}/>

    <ControlsView
            theme={theme}
            propKey="ingredients"
            label="Ingredients"
            filter={['list', 'paragraph']}
            description={ingredientsDescription}/>

    <ControlsView
        theme={theme}
        propKey="method"
        label="Method"
        filter={['orderedlist', 'paragraph']}
        description={methodDescription}/>
    </>
}

const ContentTab = {
    settings: {
        name: 'Content',
        icon: 'newspaper'
    },
    component: ContentTabBody
}

export {ContentTab};