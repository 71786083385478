import React from "react";
import { AdvertiserRoot } from "./page";



const App = () => {
    const route = '/advertiser';
    const sideMenu = {text: 'Advertiser', icon: 'ad', route}
    
    const render = ({theme, context}) => <AdvertiserRoot theme={theme} context={context} />

    return {
        route,
        sideMenu,
        render
    };
}

const AdvertiserApp = App();
export {AdvertiserApp};