import React, {useState} from 'react';
import {useHistory, useLocation, Switch, Route} from 'react-router-dom';
import { RecipeEditView, RecipeListView } from './views';

const RecipeListPage = ({theme}) => {
    const history = useHistory();
    const onEditContent = (id) => {
        history.push(`/content/recipes/edit/${id}`);
    }
    return (
        <Switch>
            <Route path="/content/recipes/edit">
                <RecipeEditView theme={theme} />
            </Route>
            <Route path="/content/recipes/new">
                <RecipeEditView theme={theme} />
            </Route>
            <Route path="/content/recipes">
                <RecipeListView onEdit={onEditContent} theme={theme} />
            </Route>
        </Switch>
    )
}

export {RecipeListPage};