import React, {useState} from "react";
import styled from "styled-components";
import {
    Toolbar,
    ToolbarItem,
    Text,
    Heading,
    ImageControl,
ComponentFactory,
ControlPicker} from "@centrepointmedia/react-ui";
import { MediaPicker } from "../../../media/views";
import { Recipes } from "../data";

const Controls = styled.div`
    padding: 50px;
    background: #f1f1f1;
    border-radius: 10px;
    margin-bottom: 20px;
    h2 {
        font-size: 1.15rem !important;
    }
`;

const ControlsToolbarWrapper = styled.div`
    margin-bottom: 20px;
`;

const ControlsView = ({theme, label, description, filter, propKey, max}) => {
    const [state, setState] = useState({
        addControl: false,
        isSorting: false,
        modals: []
    });

    const controller = Recipes.useContainer();
    
    const controls = controller.model.recipe[propKey];
    
    const onAddControl = (control) => {
        const newControls = [...controls, control];
        controller.methods.update({[propKey]: newControls});
        setState(s => ({...s, addControl: false}));
    }

    const onMoveControlUp = (idx) => {
        if(!state.isSorting || idx === 0) {
            return;
        }

        return () => onMoveControl(idx, idx-1);
    }

    const onMoveControlDown = (idx) => {
        if(!state.isSorting || idx >= controls.length-1) {
            return;
        }

        return () => onMoveControl(idx, idx+1);
    }
 
    const onMoveControl = (oldIdx, idx) => {
        console.log(oldIdx);
        const control = {...controls[oldIdx]};
        const sortedControls = [...controls].filter((_, i) => i !== oldIdx);
        sortedControls.splice(idx, 0, control);
        console.log(sortedControls);
        controller.methods.update({[propKey]: sortedControls});
    }

    const onEditValueFactory = (idx) => value => onEditComponentValue(idx, value);
    const onEditComponentValue = (idx, value) => {
        const newControls = [...controls];
        newControls[idx] = {...value};
        controller.methods.update({[propKey]: newControls});
    }

    const onRemoveFactory = (idx) => () => {
        const newControls = [...controls].filter((_, i) => i !== idx);
        controller.methods.update({[propKey]: newControls});
    }

    const onCancelAddControl = () => {
        setState(s => ({...s, addControl: false}));
    }

    const onShowControlPicker = () => {
        setState(s => ({...s, addControl: true}));
    }

    const onToggleReorder = () => {
        setState(s => ({...s, isSorting: !s.isSorting}));
    }

    const onControlEventFactory = (idx) => (eventName, eventArgs) => {
        let modal = null;
        switch(eventName) {
            case ImageControl.events.onAddImage:
                modal = {type:'MediaPicker', idx};
                break;
        }

        if(modal !== null) {
            setState(s => ({...s, modals:[...s.modals, modal]}))
        }
    }

    const shouldShowModal = (modalType) => {
        console.log(state.modals);
        console.log(state.modals.some(m => m.type === modalType));
        return state.modals.some(m => m.type === modalType);
    }
    const onAddImage = (images) => {
        console.log(images);
        const image = images[0];
        const modal = state.modals.find(m => m.type === 'MediaPicker');
        const control = controls[modal.idx];
        setState(s => ({...s, modals:[...s.modals.filter(m => m.type !== 'MediaPicker')]}));
        onEditComponentValue(modal.idx, {...control, image});
    }

    const onCancelAddImage = () => {

    }

    const onMaxReached = () => controls.length === max;

    return (
        <>
            <Heading level={4} text={label} />
            <Text>{description}</Text>
            { state.addControl && <ControlPicker theme={theme} filter={filter} onCancel={onCancelAddControl} onSelect={onAddControl}/> }
            <Controls>
                {controls.map((ctrl, idx) => {
                    console.log(ctrl);
                    const Component = ComponentFactory.render(ctrl);
                    return <Component 
                            key={`control-${idx}`}
                            value={ctrl}
                            reorder={state.isSorting}
                            onRemove={onRemoveFactory(idx)}
                            onEvent={onControlEventFactory(idx)}
                            onMoveDown={controls.length > 0 && idx < controls.length-1 ? onMoveControlDown(idx) : null}
                            onChange={state.isSorting ? null : onEditValueFactory(idx)}
                            onMoveUp={controls.length > 0 && idx > 0 ? onMoveControlUp(idx) : null}
                        />
                })}
            </Controls>
            <ControlsToolbarWrapper>
                <Toolbar theme={theme}>
                    {!onMaxReached()  && <ToolbarItem iconName="plus" onClick={onShowControlPicker}>Add control</ToolbarItem>}
                    <ToolbarItem iconName="sort-numeric-down" active={state.isSorting} onClick={onToggleReorder}>Reorder / Remove</ToolbarItem>
                </Toolbar>    
            </ControlsToolbarWrapper>
            {shouldShowModal('MediaPicker') && <MediaPicker visible={shouldShowModal('MediaPicker')} theme={theme} maxImageSelection={1} onOk={onAddImage} onCancel={onCancelAddImage} />}
        </>
    );
}

export {ControlsView};
