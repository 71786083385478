import React from 'react';
import { Advertiser } from '../apps/advertisers/data/use.advertiser';
import { Advertisers } from '../apps/advertisers/data/use.advertisers';
import { ArticleEdit } from '../apps/content/articles/data/use.articles';
import { ArticleList } from '../apps/content/data/use.content';
import { Recipes, RecipeSearch } from '../apps/content/recipes/data/use.recipes';
import { Reviews, ReviewSearch } from '../apps/content/reviews/data/use.reviews';
import { Http } from '../core/http/use.http';

const Containers = ({children}) => {
    return (
        <Http.Provider>
            <ArticleEdit.Provider>
                <ArticleList.Provider>
                    <Advertiser.Provider>
                        <Advertisers.Provider>
                            <Recipes.Provider>
                                <RecipeSearch.Provider>
                                    <Reviews.Provider>
                                        <ReviewSearch.Provider>
                                            {children}
                                        </ReviewSearch.Provider>
                                    </Reviews.Provider>
                                </RecipeSearch.Provider>
                            </Recipes.Provider>
                        </Advertisers.Provider>
                    </Advertiser.Provider>
                </ArticleList.Provider>
            </ArticleEdit.Provider>
        </Http.Provider>
    )
};

const Store = React.memo(Containers);
export {Store};