import React, {useReducer, useState} from 'react';
import { createContainer } from 'unstated-next';
import { Http } from '../../../core/http';

const Actions = {
    Load: 'AVS.LOAD',
    LoadRoots: 'AVS.LOADROOTS',
    SelectRoot: 'AVS.SELECTROOT',
    LoadRoot: 'AVS.LOADROOT',
    LoadChildren: 'AVS.LOADCHILDREN',
    LoadSubCategories: 'AVS.LOADSUBCATEGORIES',
    LoadTags: 'AVS.LOADTAGS',
    UpdateCategory: 'AVS.UPDATECATEGORY'
}

const INITIAL_STATE = {
    advertisers: [],
    roots: [],
    selectedRoot: {
        _id: '',
        children: [],
        categories: [],
        advertiser: null
    },
    tags: []
}

const breadcrumbCache = {};

const reducer = (state = INITIAL_STATE, {type, payload}) => {
    switch (type) {
        case Actions.Load:
            return {...state, advertisers: [...payload].sort((a, b) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            })};
        case Actions.LoadRoots:
            return {...state, roots: [...payload
                .map((r, idx) => ({text: r.name, ...r, icon: 'folder-open', active: (state.selectedRoot._id && state.selectedRoot._id.length === 0 && idx === 0)}))
                .sort((a, b) => {
                    if(a.text < b.text) { return -1; }
                    if(a.text > b.text) { return 1; }
                    return 0;
                })   
            ]};
        case Actions.LoadTags:
            return {...state, tags: payload.sort((a, b) => {
                if(a.paths[0] < b.paths[0]) { return -1; }
                if(a.paths[0] > b.paths[0]) { return 1; }

                if(a.paths.length > 1 && b.paths.length === 1) return 1;
                if(a.paths.length === 1 && b.paths.length > 1) return -1;

                if(a.paths[1] < b.paths[1]) { return -1; }
                if(a.paths[1] > b.paths[1]) { return 1; }
                return 0;
            })};
        case Actions.SelectRoot:
            console.log(payload)
            return {
                ...state,
                selectedRoot: {...INITIAL_STATE.selectedRoot, ...payload}
            };
        case Actions.UpdateCategory: {
            const roots = [...state.roots.filter(r => r._id !== payload._id), payload].sort((a, b) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });

            console.log(roots);
            return {...state, roots: [...roots]};
        }

        case Actions.LoadSubCategories:
            return {...state, selectedRoot: {...state.selectedRoot, categories: [...payload
                .map(r => ({text: r.name, ...r, icon: 'folder-open', active: state.selectedRoot === r._id}))
                .sort((a, b) => {
                    if(a.text < b.text) { return -1; }
                    if(a.text > b.text) { return 1; }
                    return 0;
                })
            ]}};
        case Actions.LoadChildren:
            return {...state, selectedRoot: {...state.selectedRoot, children: [...payload.sort((a, b) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            })]}};
        default:
            return state;
    }
}

const useAdvertisers = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const http = Http.useContainer();

    const startHttp = () => new Promise((resolve, _) => {
        http.start();
        resolve();
    });

    const loadAdvertisers = () => fetch('/api/v1/advertiser/', {
        method: 'GET'
    });

    const loadRoots = () => fetch('/api/v1/advertiser/category', {method: 'GET'});
    const loadChildCategory = (id) => fetch(`/api/v1/advertiser/category/${id}/categories`);
    const loadChildren = (id) => fetch(`/api/v1/advertiser/category/${id}/children`);
    const loadTags = () => fetch('/api/v1/advertiser/tags');
    const loadBreadcrumbs = (paths) => {
        const cacheKey = paths.join("-");
        const crumbs = breadcrumbCache[cacheKey]; 
        if(crumbs) 
            return Promise.resolve(crumbs);

        return fetch(`/api/v1/advertiser/breadcrumbs`, {method: "POST", body: JSON.stringify({paths}), headers: {"Content-Type": "application/json"}})
            .then(fromJson)
            .then(paths => {
                breadcrumbCache[cacheKey] = paths;
                return paths;
            });
    }

    const putCategory = (category) => fetch('/api/v1/advertiser/category', {method: "PUT", body: JSON.stringify(category), headers: {"Content-Type": "application/json"}})


    const saveSection = (name, parent, type) => fetch(`/api/v1/advertiser/category`, {method: "POST", body: JSON.stringify({name, parent, type}), headers: {'Content-Type': 'application/json'}});
    const fromJson = (res) => res.json();
    const dispatchAdvertisers = (res) => dispatch({type: Actions.Load, payload: res});
    const dispatchRoots = (res) => dispatch({type: Actions.LoadRoots, payload: res});
    const dispatchSelectRoot = (id) => dispatch({type: Actions.SelectRoot, payload: id});
    const dispatchLoadSubCategories = (res) => dispatch({type: Actions.LoadSubCategories, payload: res});
    const dispatchLoadChildren = (res) => dispatch({type: Actions.LoadChildren, payload: res});
    const dispatchCategoryUpdate = (res) => dispatch({type: Actions.UpdateCategory, payload: res});
    const dispatchTags = (res) => dispatch({type: Actions.LoadTags, payload: res});

    const getTags = () => startHttp()
        .then(_ => loadTags())
        .then(fromJson)
        .then(dispatchTags)
        .then(http.end)
        .catch(http.error);

    const selectRoot = (root) => typeof root !== 'undefined' ? startHttp()
        .then(_ => dispatchSelectRoot(root))
        .then(_ => loadChildren(root._id))
        .then(fromJson)
        .then(dispatchLoadChildren)
        .then(http.end) : null

    const getRoots = () => startHttp()
        .then(loadRoots)
        .then(fromJson)
        .then(dispatchRoots)
        .then(http.end);

    const updateCategory = (category) => startHttp()
        .then(_ => putCategory(category))
        .then(fromJson)
        .then(dispatchCategoryUpdate)
        .then(http.end)

    const load = () => startHttp()
        .then(loadAdvertisers)
        .then(fromJson)
        .then(dispatchAdvertisers)
        .then(http.end);

    const addSection = (section) => startHttp()
        .then(_ => saveSection(section, null))
        .then(getRoots);

    const addCategory = ({name, parent, type}) => startHttp()
        .then(_ => saveSection(name, parent, type))
        .then(_ => selectRoot(parent));

    return {
        ...state,
        load,
        getRoots,
        updateCategory,
        loadBreadcrumbs,
        selectRoot,
        addSection,
        addCategory,
        getTags,
        isLoading: http.isLoading,
        error: http.error
    }
}

const Advertisers = createContainer(useAdvertisers);
export {Advertisers};