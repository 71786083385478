const ArticleApi = () => {
    const search = async ({text, page, sort, filters, pageSize, published}) => {
        const response = await fetch('/api/v1/articles/search',
            {
                method: 'POST',
                body: JSON.stringify({text, page, sort, filters, pageSize, published}),
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                }});
        return response.json();
    }

    const article = async (id) => {
        const response = await fetch('/api/v1/articles/' + id);
        return response.json();
    }

    const preview = async (article) => {
        const response = await fetch('/api/v1/articles/preview', {
            method: 'POST',
            body: JSON.stringify(article),
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        console.log(response);
        return response.text();
    }

    const previewItem = (id) => fetch('/api/v1/articles/preview/' + id)
        .then(res => res.text());

    const archive = async (id) => {
        const response = await fetch('/api/v1/articles/' + id, {
            method: 'DELETE'
        });
        return response.json();
    }

    const publish = async (id) => {
        const response = await fetch('/api/v1/articles/publish/' + id, {
            method: 'GET'
        });
        return response.json();
    }

    const save = async (article) => {
        const response = await fetch('/api/v1/articles', {
            method: 'POST',
            body: JSON.stringify(article),
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        return response.json();
    }

    return {search, article, save, archive, publish, preview, previewItem};
}

export {ArticleApi};