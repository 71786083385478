import React from 'react';
import { EditableList, Label } from '@centrepointmedia/react-ui';
import { Reviews } from '../data/use.reviews';

const CategoryTabBody = ({theme}) => {
    const controller = Reviews.useContainer();
    const model = controller.model.review;

    const onAddTag = (tag) => controller.methods.update({tags: [...model.tags, ...tag.map(t => t.toLowerCase())]});

    const onRemoveTag = (index) => {
        const tags = [...model.tags].filter((_, idx) => idx !== index);
        controller.methods.update({tags});
    }
    
    return <>
        <Label text="Tags" theme={theme} />
        <EditableList theme={theme} iconName={"tags"} items={model.tags} onAdd={onAddTag} onRemove={onRemoveTag} />
    </>
}

const CategoryTab = {
    settings: {
        name: 'Tags',
        icon: 'tags'
    },
    component: CategoryTabBody
}

export {CategoryTab};