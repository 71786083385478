import React from "react";
import {
    FileUploader
} from "@centrepointmedia/react-ui"

const AssetUploader = ({theme, onFileUpload}) => {
    return (
        <FileUploader onFileUploaded={onFileUpload} theme={theme}  instructions="Please drop an image here" />
    )
};

export {AssetUploader};