import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { EditableList, TextInput, Label, FaIcon, Info, Question } from '@centrepointmedia/react-ui';
import {useMediaController} from '../data';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Image = styled.div`
    height: 300px;
    margin: 4px 8px 20px;
    background: url('${props => props.src}');
    background-size: cover;
    background-position: center;
    width: calc(100% - 16px);
    border-radius: 6px;
    position: relative;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.75);
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
    
    i {
        color: white;
    }
`;

const MediaEdit = ({theme, onImageUpdated, onClose, onImageRemoved}) => {
    const controller = useMediaController();
    const {selectedImage, newImageSchema} = controller;
    const [state, setState] = useState(selectedImage ? {...selectedImage} : {...newImageSchema});
    const [uiState, setUiState] = useState({report: []});

    useEffect(() => {
        if(controller.selectedImage) {
            setState({...controller.selectedImage});
        }
    }, [controller.selectedImage]);

    const onTitleChange = (title) => onImageUpdated({...selectedImage, title})
    const onTagsAdd = (tag) => onImageUpdated({...selectedImage, tags: [...selectedImage.tags, ...tag]});
    const onRemoveTag = (index) => {
        const tags = [...selectedImage.tags].filter((_, idx) => idx !== index);
        onImageUpdated({...selectedImage, tags});
    }

    const onAttemptRemoval = () => {
        fetch(`/api/v1/media/${selectedImage.id}`)
            .then(res => res.json())
            .then(res => res.map(doc => doc.title))
            .then(res => setUiState(s => ({...s, report: res, canRemove: res.length === 0})));
    }

    const onDismissReport = () => setUiState(s => ({...s, report: []}));
    const onDismissRemoveImage = () => setUiState(s => ({...s, canRemove: false}));
    const onRemoveImage = () => {
        fetch(`/api/v1/media/${selectedImage.id}`, {
            method: "DELETE"
        })
            .then(_ => {
                setUiState(s => ({...s, canRemove: false}));
                setTimeout(() => {
                    if(onImageRemoved) {
                        onImageRemoved();
                    }
                }, 800);

                if(onClose) {
                    onClose();
                }
            });
    }
    return (
            <Container>
                {state.url && <Image src={state.url}>
                    <Overlay onClick={onAttemptRemoval}>
                        <FaIcon name={"trash-alt"} theme={theme} style={"dual"} fontSize={5} sizeUnit={"rem"} />
                    </Overlay>
                </Image>}
                <TextInput label="Title" theme={theme} value={state.title} onValueChange={onTitleChange} />
                <Label theme={theme} text="Tags" />
                <EditableList theme={theme} iconName={"tags"} items={state.tags} onAdd={onTagsAdd} onRemove={onRemoveTag} />
                {uiState.report.length > 0 && <Info theme={theme} onOk={onDismissReport} message={`
                    <p>This image can not be deleted.<br /> It is used in the following features/recipes/reviews</p>
                    <ul style="margin: 0">
                        ${uiState.report.map((title, idx) => `<li style="text-align: left">${title}</li>`)}
                    </ul>
                `}/>}
                {uiState.canRemove &&
                    <Question theme={theme} message={"Are you sure you wish to delete this image?"} onYes={onRemoveImage} onNo={onDismissRemoveImage} />}
            </Container>
    )
}

export {MediaEdit};