import React from 'react';
import {
    Toolbar,
    ToolbarItemInput,
    ToolbarItem,
} from "@centrepointmedia/react-ui";

const MediaToolbar = ({theme, onNew, onSearch, onClearSearch, canAddImage, value}) => {
    return (
        <Toolbar theme={theme}>
            {canAddImage && <ToolbarItem iconName="plus" onClick={onNew}>New image</ToolbarItem> }
            <ToolbarItemInput isFullWidth={true} iconName="search" onClear={onClearSearch} onClick={onSearch} value={value} />
        </Toolbar>
    )
}

export {MediaToolbar};