import React from "react";
import {MediaPage} from "./views";

const App = () => {
    const route = '/media';
    const sideMenu = {text: 'Media', icon: 'images', route}

    const render = ({theme, context}) => <MediaPage theme={theme} canAddImage={true} />

    return {
        route,
        sideMenu,
        render
    };
}

const MediaApp = App();
export {MediaApp};