import React, {useReducer} from 'react';
import {createContainer} from 'unstated-next';

const HttpActions = {
    Start: 'HA.START',
    End: 'HA.END',
    Error: 'HA.ERROR'
};

const MODEL = {
    isLoading: false,
    error: null
};

const httpReducer = (state, {type, payload}) => {
    switch(type) {
        case HttpActions.Start:
            return {...state, isLoading: true, error: null};
        case HttpActions.End:
            return {...state, isLoading: false};
        case HttpActions.Error: 
            return {...state, isLoading: false, error: payload};
        default:
            return state;
    }
}

const useHttp = (inititalState = MODEL) => {
    const [state, dispatch] = useReducer(httpReducer, inititalState);
    const start = () => dispatch({type: HttpActions.Start});
    const end = () => dispatch({type: HttpActions.End});
    const error = (err) => dispatch({type: HttpActions.Error, payload: err});

    const startAsync = () => new Promise((resolve, _) => {start(); resolve();});
    return {
        isLoading: state.isLoading,
        error: state.error,
        start,
        startAsync,
        end,
        error
    };
}

const Http = createContainer(useHttp);

export {Http, HttpActions};