import React, {Fragment, useState, useEffect} from "react";
import {
    TextInput,
    MultiLineText,
    ImageField,
    DropDown,
    Label,
    DatePicker,
} from "@centrepointmedia/react-ui";
import {DateTime as DT} from 'luxon';
import styled from 'styled-components';
import { MediaPicker } from "../../../media/views";
import { Reviews } from "../data/use.reviews";
import { MovieGenres } from "../data/genres";
import { Formats } from "../data/formats";
import { AgeRatings } from "../data/age.ratings";

const DropDownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 4px;
    }
    div {
        button {
            justify-content: space-between;
        }
    }
`;

const FieldWrapper = styled.div`
    display: flex;
    div {
        margin: 0;
    }
`;

const ColumnWrapper = styled.div`
    margin-right: 10px !important;
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 4px;
    }
`;

const MetaTabBody = ({theme}) => {
    const [state, setState] = useState({showPicker: false});
    const controller = Reviews.useContainer();

    const {title, summary, socialImage, genres, language, releaseDate, format, runtime, ageRating} = controller.model.review;

    const onValueChange = (value, prop) => controller.methods.update({[prop]: value});

    const onTitleChange = (value) => onValueChange(value, 'title');
    const onRuntimeChange = (value) => /\d/.test(value) ? onValueChange(value, 'runtime') : null;
    const onSummaryChange = (value) => onValueChange(value, 'summary');
    const onRemoveHero = () => onValueChange({url: ''}, 'socialImage');
    const onImageSelected = (images) => {
        onValueChange(images[0], 'socialImage');
        onHideImagePicker();
    }

    console.log(releaseDate);
    const onGenreSelect = (items) => onValueChange(items, 'genres');
    const onFormatChange = (items) => onValueChange(items, 'format');
    const onAgeRatingChange = (rating) => onValueChange(rating, 'ageRating');

    const getDate = (dateStr) => {
        const date = DT.fromISO(dateStr);
        return DT.utc(date.year, date.month, date.day);
    }
    const onReleaseDateChange = (rd) => onValueChange(getDate(rd), 'releaseDate');

    const onShowImagePicker = () => setState(s => ({...s, showPicker: true}));
    const onHideImagePicker = () => setState(s => ({...s, showPicker: false}));

    const formatReleaseDate = () => typeof releaseDate === 'string' ? releaseDate : releaseDate.toISO();
    return (
        <Fragment>
            <TextInput theme={theme} label={"Title"} value={title} onValueChange={onTitleChange} />
            <FieldWrapper>
                <ColumnWrapper>
                    <TextInput theme={theme} label="Runtime (minutes)" value={runtime} onValueChange={onRuntimeChange} />
                </ColumnWrapper>
                <ColumnWrapper>
                    <Label theme={theme} text="Genres" />
                    <DropDown theme={theme} title="Please select an item" maxSelect={0} items={MovieGenres} onSelect={onGenreSelect} value={genres.map(g => g.key)} />
                </ColumnWrapper>
                <ColumnWrapper>
                    <Label theme={theme} text="Released via" />
                    <DropDown theme={theme} title="Please select an item" maxSelect={0} items={Formats} onSelect={onFormatChange} value={format.map(f => f.key)} />
                </ColumnWrapper>
                <ColumnWrapper>
                    <Label theme={theme} text="Age Rating" />
                    <DropDown theme={theme} title="Please select an item" maxSelect={1} items={AgeRatings} onSelect={onAgeRatingChange} value={(ageRating ? ageRating.map(ar => ar.key) : [])} />
                </ColumnWrapper>
                <ColumnWrapper>
                    <DatePicker theme={theme} label="Release date" value={formatReleaseDate()} onValueChange={onReleaseDateChange} />
                </ColumnWrapper>
            </FieldWrapper>
            <MultiLineText label="Summary" value={summary} onChange={onSummaryChange} />   
            <ImageField value={socialImage.url} label="Social image" onAdd={onShowImagePicker} onRemove={onRemoveHero}/>  
            {state.showPicker && <MediaPicker theme={theme} visible={state.showPicker} onOk={onImageSelected} onCancel={onHideImagePicker} maxImageSelection={1} />}
        </Fragment>
    )
}

const MetaTab = {
    settings: {
        name: 'Meta',
        icon: 'info-circle'
    },
    component: MetaTabBody
}

export {MetaTab};