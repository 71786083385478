import React from 'react';
import styled from 'styled-components';
import {Table, TableColumn, TableColumnTitle, Label, FaIcon, useNotification} from '@centrepointmedia/react-ui';
import { ArticleEdit } from '../data/use.articles';

const TableContainer = styled.div`
    margin-top: 5px;
`;

const AccessoryWrapper = styled.div`
    text-align: right;
`;

const keys = ['Username', 'Version Number', 'Updated']
const HistoryTabBody = ({theme}) => {
    const controller = ArticleEdit.useContainer();
    const notificationManager = useNotification();
    const {history} = controller;
    
    const loadVersion = (versionId) => {
        controller.loadVersion(versionId);
        notificationManager.add({message: 'Version loaded', icon: 'info'});
    }
    
    return (
        <>
            <Label theme={theme} text="Audit trail" />
            <TableContainer>
                <Table theme={theme} items={history} onRenderRow={(a, index) => (
                    <>
                        <TableColumn key={`row-col1-${index}`}>
                            <TableColumnTitle>{a.user}</TableColumnTitle>
                        </TableColumn>
                        <TableColumn key={`row-col2-${index}`}>
                            <TableColumnTitle>{a.id}</TableColumnTitle>
                        </TableColumn>
                        <TableColumn key={`row-col3-${index}`}>
                            <TableColumnTitle>{a.updated}</TableColumnTitle>
                        </TableColumn>
                    </>
                    
                )} onRenderAccessory={(row) => 
                    <TableColumn>
                        <AccessoryWrapper>
                            {row.id != controller.model.id && <FaIcon onClick={() => loadVersion(row.id)} name="folder-open" fontSize={1} sizeUnit={'rem'} style='light' label="Open version" />}
                            {row.id == controller.model.id && <FaIcon onClick={() => loadVersion(row.id)} name="check-circle" fontSize={1} sizeUnit={'rem'} style='light' label="Active version" />}
                        </AccessoryWrapper>
                    </TableColumn>
                    }
                onRenderColumnHead={(row) => keys[row]}
                renderHeaders={true} columnCount={3}/>
            </TableContainer>
        </>
    )
}

const HistoryTab = {
    settings: {
        name: 'History',
        icon: 'history'
    },
    component: HistoryTabBody
}

export {HistoryTab};