import React, {Fragment, useState} from "react";
import { ControlsView } from "../../components/controls.component";

const articleContentDescription = "Use this section to add content to the article, by using \"controls\" it makes it easier to inject advertising and rearrange the article content.";
const heroDescription = "Use this section to add a hero to the article."
const ContentTabBody = ({theme}) => {
    return <>
    <ControlsView
        theme={theme}
        propKey="hero"
        label="Hero"
        max={1}
        filter={['image', 'video']}
        description={heroDescription}/>

    <ControlsView
        theme={theme}
        propKey="controls"
        label="Article Content"
        description={articleContentDescription}/>
    </>
}

const ContentTab = {
    settings: {
        name: 'Content',
        icon: 'newspaper'
    },
    component: ContentTabBody
}

export {ContentTab};