import React, {useReducer, useEffect} from 'react';
import { createContainer } from 'unstated-next';
import { Http } from '../../../core/http';
import { ArticleApi } from './../articles/data/article.api';

const Actions = {
    SetPage: 'CA.SETPAGE',
    SetQuery: 'CA.SETQUERY',
    SetSort: 'CA.SETSORT',
    SetFilter: 'CA.SETFILTER',
    Search: 'CA.SEARCH',
    ToggleArchive: 'CA.TOGGLEARCHIVE',
    Preview: 'CA.PREVIEW',
    ResetPreview: 'CA.RESETPREVIEW'
};

const INITIAL_STATE = {
    searchText: '',
    page: 1,
    filters: [],
    results: {
        items: [],
        facets: [],
        total: 0
    },
    sort: {
        key: 'date',
        direction: 'desc'
    },
    viewArchived: false,
    previewHtml: ''
};

const reducer = (state = INITIAL_STATE, {type, payload}) => {
    switch(type) {
        case Actions.SetQuery:
            return {...state, searchText: payload, sort: {key: payload.length > 0 ? "score" : "date", direction: "desc"}};
        case Actions.SetPage:
            return {...state, page: payload};
        case Actions.Search:
            return {...state, results: {...payload}};
        case Actions.ToggleArchive:
            return {...state, viewArchived: !state.viewArchived};
        case Actions.SetSort:
            return {...state, sort: {...payload}};
        case Actions.SetFilter:
            return {...state, filters: [...payload]};
        case Actions.Preview:
            return {...state, previewHtml: payload};
        case Actions.ResetPreview:
            return {...state, previewHtml: ''};
        default:
            return state;
    }
}

const PAGE_SIZE = 20;
const useArticleList = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const http = Http.useContainer();
    const api = ArticleApi();

    useEffect(() => {
        search();
    }, []);

    useEffect(() => {
        if(state.page === 1) {
            search();
            return;
        }
        setPage(1);
    }, [state.searchText, state.sort, state.filters, state.viewArchived]);

    useEffect(() => {
        search();
    }, [state.page])

    const setQuery = (text) => dispatch({type: Actions.SetQuery, payload: text});
    const setPage = (page) => dispatch({type: Actions.SetPage, payload: page});
    const setSort = (sort) => dispatch({type: Actions.SetSort, payload: sort});
    const setFilter = (filter) => dispatch({type: Actions.SetFilter, payload: [filter]});
    const resetFilter = () => dispatch({type: Actions.SetFilter, payload: []});
    const toggleViewMode = () => dispatch({type: Actions.ToggleArchive});
    const publish = (id) => {
        http.start();
        api.publish(id).then(_ => {
            setTimeout(search, 500);
        });
    }
    const archive = (id) => {
        http.start();
        api.archive(id).then(_ => {
            setTimeout(search, 500);
        });
    }

    const preview = (id) => {
        http.start();
        api.previewItem(id).then(res => {
            http.end();
            dispatch({type: Actions.Preview, payload: res});
        }).catch(http.error);
    }

    const resetPreview = () => dispatch({type: Actions.ResetPreview});

    const search = () => {
        http.start();
        return api.search({text: state.searchText, filters: state.filters, sort: state.sort, page: state.page, pageSize: PAGE_SIZE, published: !state.viewArchived})
            .then(res => {
                dispatch({type: Actions.Search, payload: res});
                http.end();
            })
            .catch(err => http.error(err));
    }

    return {...state, 
        setQuery,
        setPage,
        search,
        isLoading: http.isLoading,
        setSort,
        toggleViewMode,
        setFilter,
        resetFilter,
        publish,
        archive,
        preview,
        resetPreview};
};

const ArticleList = createContainer(useArticleList);
export {ArticleList};