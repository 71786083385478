import React, {useState} from 'react';
import {ContentListPanel} from "../../panel/content.list.panel";
import {useHistory, useLocation, Switch, Route} from 'react-router-dom';
import { ArticleEditView } from '../views/edit.view';

const ArticleListPage = ({theme}) => {
    const history = useHistory();
    const onEditContent = (id) => {
        history.push(`/content/articles/edit/${id}`);
    }
    return (
        <Switch>
            <Route path="/content/articles/edit">
                <ArticleEditView theme={theme} />
            </Route>
            <Route path="/content/articles/new">
                <ArticleEditView theme={theme} />
            </Route>
            <Route path="/content/articles">
                <ContentListPanel onEdit={onEditContent} type="article" theme={theme} />
            </Route>
        </Switch>
    )
}

export {ArticleListPage};