import React, {Fragment, useState, useEffect} from "react";
import {
    TextInput,
    MultiLineText,
    ImageField,
    ImagePicker,
    SlidingModal,
    Toolbar,
    ToolbarItemInput,
    ToolbarItem
} from "@centrepointmedia/react-ui";
import { MediaPicker } from "../../../media/views";
import { ArticleEdit } from "../data/use.articles";

const MetaTabBody = ({theme}) => {
    const [state, setState] = useState({showPicker: false});
    const controller = ArticleEdit.useContainer();

    const {title, summary, socialImage} = controller.model;

    const onValueChange = (value, prop) => controller.update({[prop]: value});

    const onTitleChange = (value) => onValueChange(value, 'title');
    const onSummaryChange = (value) => onValueChange(value, 'summary');
    const onRemoveHero = () => onValueChange({url: ''}, 'socialImage');
    const onImageSelected = (images) => {
        onValueChange(images[0], 'socialImage');
        onHideImagePicker();
    }

    const onShowImagePicker = () => setState(s => ({...s, showPicker: true}));
    const onHideImagePicker = () => setState(s => ({...s, showPicker: false}));

    return (
        <Fragment>
            <TextInput theme={theme} label={"Title"} value={title} onValueChange={onTitleChange} />
            <MultiLineText label="Summary" value={summary} onChange={onSummaryChange} />   
            <ImageField value={socialImage.url} label="Social image" onAdd={onShowImagePicker} onRemove={onRemoveHero}/>  
            {state.showPicker && <MediaPicker theme={theme} visible={state.showPicker} onOk={onImageSelected} onCancel={onHideImagePicker} maxImageSelection={1} />}
        </Fragment>
    )
}

const MetaTab = {
    settings: {
        name: 'Meta',
        icon: 'info-circle'
    },
    component: MetaTabBody
}

export {MetaTab};