import styled from "styled-components";
import {ImageField, FaIcon, Button, SlidingModal, FileUploader, Question} from "@centrepointmedia/react-ui";
import React, {useState} from "react";
import {AssetUploader} from "./asset.uploader";

const ImageWrapper = styled.div`
    display: flex;
`;
const Buttons = styled.div`
	i {
	  margin-right: 15px;
	  cursor: pointer;
	}
`;

const ButtonWrapper = styled.div`
	text-align: center;
  	cursor: pointer;
  	padding-top: 15px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  padding-left: 15px;
`;

const Wrapper = styled.div``;
const AssetsView = ({theme, controller}) => {
	const [state, setState] = useState({
		currentItem: [],
		showEdit: false,
		asset: null,
		assetType: "",
		assetFormat: "",
		showMediaPicker: false,
		editIndex: -1,
		indexToRemove: -1,
		canDelete: false
	});

	const editItem = (currentItem, idx) => setState(s => ({...s, showEdit: true, currentItem, editIndex: idx}));
	const onAddItemTouch = () => editItem([], -1);
	const onEditItemTouch = (currentItem, idx) => editItem(currentItem, idx);

	const onRemoveItemTouch = (idx) => setState(s => ({...s, canDelete: true, indexToRemove: idx}))

	const onRemoveItem = () => {
		console.log(`Removing: ${state.indexToRemove}`)
		const assets = [...controller.digitalAssets.filter((_, index) => index !== state.indexToRemove)];
		controller.setAssets("digitalAssets", assets);
		onCancelRemoveItem();
	}

	const onAddAsset = (type) => setState(s => ({...s, showMediaPicker: true, assetType: type}));
	const getAsset = (assets, type) => {
		console.log(assets);
		const asset = (assets || []).find(a => a.type === type);
		return (asset || {blobUrl: ""}).blobUrl;
	}

	const setAsset = (asset) => setState(s => ({...s, asset}));

	const onUploadAsset = () => {
		if(state.asset) {
			controller.saveAsset(state.asset, state.assetType)
				.then(asset => {
					console.log(asset);
					setState(s => ({...s, currentItem: [...s.currentItem, {...asset.asset, type: asset.name}], asset: null, assetType: "", assetFormat: "", showMediaPicker: false}))
				});
		}
	}

	const onRemoveAsset = (type) => setState(s => ({...s, currentItem: [...s.currentItem.filter(i => i.type !== type)]}));

	const onCancelEdit = () => setState(s => ({...s, showEdit: false, currentItem: []}))
	const onCompleteEdit = () => {
		const assets = [...controller.digitalAssets.filter((_, idx) => idx !== state.editIndex)];
		if(state.editIndex > -1) {
			assets.splice(state.editIndex, 0, state.currentItem);
		} else {
			assets.push(state.currentItem);
		}

		controller.setAssets("digitalAssets", assets);
		onCancelEdit();
	}

	const onCancelRemoveItem = () => setState(s => ({...s, indexToRemove: -1, canDelete: false}));

	const onCancelAddAsset = () => setState(s => ({...s, showMediaPicker: false, asset: null, assetType: "", assetFormat: ""}));
	return <>
		{controller.digitalAssets.map((assets, idx) => (
			<Wrapper key={idx}>
			<Item key={idx}>
				<ImageWrapper>
					<ImageField label={"Leadboard (desktop 728px x 90px)"} value={getAsset(assets, "LBDesktop")} />
					<ImageField label={"Leadboard (mobile 320px x 50px)"} value={getAsset(assets, "LBMobile")}/>
					<ImageField label={"Content body (320px x 250px)"} value={getAsset(assets, "CB")}/>
				</ImageWrapper>
				<Buttons>
					<FaIcon name={"pencil"} theme={theme} style={"light"} sizeUnit={"rem"} fontSize={1} onClick={() => onEditItemTouch(assets, idx)} />
					<FaIcon name={"trash-alt"} theme={theme} style={"light"} sizeUnit={"rem"} fontSize={1} onClick={() => onRemoveItemTouch(idx)} />
				</Buttons>
			</Item>
			</Wrapper>
		))}
		<ButtonWrapper>
			<Button type={"primary"} theme={theme} text={"Add group"} onClick={onAddItemTouch} />
		</ButtonWrapper>
		<SlidingModal visible={state.showEdit} theme={theme} size={"medium"} onCancel={onCancelEdit} onOk={onCompleteEdit}>
			<div>
				<ImageWrapper>
					<ImageField label={"Leadboard (desktop 728px x 90px)"} onAdd={() => onAddAsset("LBDesktop")} value={getAsset(state.currentItem, "LBDesktop")} onRemove={() => onRemoveAsset("LBDesktop")} />
					<ImageField label={"Leadboard (mobile 320px x 50px)"} onAdd={() => onAddAsset("LBMobile")} value={getAsset(state.currentItem, "LBMobile")} onRemove={() => onRemoveAsset("LBMobile")} />
					<ImageField label={"Content body (320px x 250px)"} onAdd={() => onAddAsset("CB")} value={getAsset(state.currentItem, "CB")} onRemove={() => onRemoveAsset("CB")} />
				</ImageWrapper>
			</div>
		</SlidingModal>
		{state.showMediaPicker && <SlidingModal size={"medium"} theme={theme} visible={state.showMediaPicker} onCancel={onCancelAddAsset} onOk={onUploadAsset}>
			<AssetUploader onFileUpload={setAsset} />
		</SlidingModal>}
		{state.canDelete && <Question theme={theme} message={"Are you sure you wish<br/>to delete this asset group?"} onNo={onCancelRemoveItem} onYes={onRemoveItem} />}
	</>
}

export {AssetsView};