import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { SlidingModal, TextInput, FileUploader, useNotification, EditableList } from '@centrepointmedia/react-ui';
import {useMediaController} from './../data';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const FileUploadWrapper = styled.div`
    margin-bottom: 20px;
`;

const MediaCreate = ({theme, onImageUpdated}) => {
    const [state, setState] = useState({title: '', file: null, tags: []});
    const notificationManager = useNotification();

    useEffect(() => {
        if(onImageUpdated) {
            onImageUpdated(state);
        }
    }, [state.title, state.file, state.tags]);  

    const onTitleChange = (title) => setState(s => ({...s, title}));
    const onTagsAdd = (tag) => setState(s => ({...s, tags: [...s.tags, tag]}));
    const onRemoveTag = (index) => {
        const tags = [...state.tags].filter((_, idx) => idx !== index);
        setState(s => ({...s, tags}));
    }
    
    const onInvalidFile = () => {
        notificationManager.add({message: 'The file uploader only accepts images', icon: 'info'});
    }
    const onFileUploaded = (file) => setState(s => ({...s, file}));
    return (
            <Container>
                <FileUploadWrapper>
                    <FileUploader theme={theme} instructions="Please drop an image here" onInvalidFile={onInvalidFile} validFileExtensions={['png', 'jpg', 'jpeg']} onFileUploaded={onFileUploaded} />
                </FileUploadWrapper>
                <TextInput label="Title" theme={theme} value={state.title} onValueChange={onTitleChange} />
                <EditableList theme={theme} iconName={"tags"} items={state.tags} onAdd={onTagsAdd} onRemove={onRemoveTag} />
            </Container>
    )
}

export {MediaCreate};