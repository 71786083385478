import { ActionTypes } from './actions';

const INITIAL_STATE = {
    results: {
        total: 0,
        images: []
    },
    searchText: '',
    page: 1,
    pageSize: 20,
    selectedImage: null,
    newImageSchema: {
        title: '',
        url: '',
        tags: []
    },
    httpStatus: {
        isLoading: false,
        lastError: null
    }
};

const mediaReducer = (state = INITIAL_STATE, {type, payload}) => {
    console.log({type, payload})
    switch(type) {
        case ActionTypes.selectImage: 
            return {...state, selectedImage: payload};
        case ActionTypes.search.start:
            return {...state, results: {images: [], total: 0}, httpStatus: {...state.httpStatus, isLoading: true}};
        case ActionTypes.search.end: 
            return {...state, results: payload, httpStatus: {...state.httpStatus, isLoading: false}};
        case ActionTypes.search.nextPage:
            return {...state, page: state.page+1};
        case ActionTypes.search.previousPage: 
            return {...state, page: state.page-1};
        case ActionTypes.search.query:
            return {...state, searchText: payload, page: 1};
        case ActionTypes.search.reset:
            return {...state, searchText: '', page: 1};
        case ActionTypes.crud.start:
            return {...state, httpStatus: {...state.httpStatus, isLoading: true}};
        case ActionTypes.crud.end: {
            const imageIndex = state.results.images.findIndex(img => img.identifier === payload.identifier); 
            const images = [...state.results.images];
            if(imageIndex > -1) {
                console.log(payload);
                images[imageIndex] = {...payload};
            }

            return {...state, results: {...state.results, images}, selectedImage: null, httpStatus: {...state.httpStatus, isLoading: false}};
        }
        case ActionTypes.crud.edit: 
            return {...state, selectedImage: {...state.selectedImage, ...payload}};
        case ActionTypes.crud.remove:
            return {...state, results:{...state.results, images: [...state.results.image.filter(img => img.identifier !== payload)]}};
        default:
            return state;
    }
}

export {mediaReducer};