import React, {useState, useEffect} from "react";
import {
    Tabs, SlidingModal, ImageField, TextInput, Loader, Repeater
} from "@centrepointmedia/react-ui"
import {MediaPicker} from "../../media/views";
import {AssetUploader} from "./asset.uploader";
import { Advertiser } from "../data/use.advertiser";
import styled from "styled-components";
import {AssetsView} from "./assets.view";
const tabs = [
    {
        name: 'Meta'
    },
    {
        name: 'Print Assets'
    },
    {
        name: 'Digital Assets'
    }
];

const ImageWrapper = styled.div`
    display: flex;
`;

const AdvertiserEditView = ({theme, advertiser}) => {
    const [state, setState] = useState({
        showMediaPicker: false,
        showLogoPicker: false,
        activeTab: 0,
        logo: null,
        asset: null,
        assetType: "",
        assetFormat: ""
    });


    const controller = Advertiser.useContainer();

    const setLogo = (logo) => setState(s => ({...s, logo}));
    const onUploadLogo = () => {
        if(state.logo) {
            controller.uploadLogo(state.logo);
        }
    }

    const setAsset = (asset) => setState(s => ({...s, asset}));
    const onUploadAsset = () => {
        if(state.asset) {
            controller.uploadAsset(state.asset, state.assetFormat, state.assetType);
        }
        setState(s => ({...s, asset: null, assetType: "", assetFormat: "", showMediaPicker: false}));
    }

    const onAddLogo = () => setState(s => ({...s, showLogoPicker: true}));
    const onCancelAddLogo = () => setState(s => ({...s, showLogoPicker: false}));

    const onRemoveAsset = (format, type) => {
        controller.removeAsset(format, type);
    }
    const onAddAsset = (format, type) => setState(s => ({...s, showMediaPicker: true, assetType: type, assetFormat: format}));
    const onCancelAddAsset = () => setState(s => ({...s, showMediaPicker: false, asset: null, assetType: "", assetFormat: ""}));

    const onTabSelect = (idx) => setState(s => ({...s, activeTab: idx}));
    const renderTabBody = () => {
        const {activeTab} = state;
        switch(activeTab) {
            case 0:
                return renderMetaTab();
            case 1:
                return renderPrintAssetsTab();
            case 2:
                return renderDigitalAssetsTab();
        }

        return null;
    }

    const getAsset = (assets, type) => {
        const asset = (assets || []).find(a => a.type === type);
        return (asset || {blobUrl: ""}).blobUrl;
    }
    
    const renderPrintAssetsTab = () => (
        <>
            <ImageWrapper>
                <ImageField label={"Panel 1 (62mm x 156mm)"} onAdd={() => onAddAsset("printAssets", "Panel1")} value={getAsset(controller.printAssets, "Panel1")} onRemove={() => onRemoveAsset("printAssets", "Panel1")} />
                <ImageField label={"Panel 2 (170mm x 30mm)"} onAdd={() => onAddAsset("printAssets", "Panel2")} value={getAsset(controller.printAssets, "Panel2")} onRemove={() => onRemoveAsset("printAssets", "Panel2")} />
                <ImageField label={"Panel 3 (215mm x 30mm)"} onAdd={() => onAddAsset("printAssets", "Panel3")} value={getAsset(controller.printAssets, "Panel3")} onRemove={() => onRemoveAsset("printAssets", "Panel3")} />
            </ImageWrapper>
        </>
    )

    const renderDigitalAssetsTab = () => <>
            <AssetsView theme={theme} controller={controller} />
            <TextInput theme={theme} label={"Asset URL"} value={controller.assetUrl} onValueChange={controller.setUrl}/>
        </>

    const renderDigitalAssetsTabOLD = () => controller.digitalAssets.map((assets, index) => <div key={`asset-${index}`}>
            <ImageWrapper>
                <ImageField label={"Leadboard (desktop 728px x 90px)"} onAdd={() => onAddAsset("digitalAssets", "LBDesktop")} value={getAsset(assets, "LBDesktop")} onRemove={() => onRemoveAsset("digitalAssets", "LBDesktop")} />
                <ImageField label={"Leadboard (mobile 320px x 50px)"} onAdd={() => onAddAsset("digitalAssets", "LBMobile")} value={getAsset(assets, "LBMobile")} onRemove={() => onRemoveAsset("digitalAssets", "LBMobile")} />
                <ImageField label={"Content body (320px x 250px)"} onAdd={() => onAddAsset("digitalAssets", "CB")} value={getAsset(assets, "CB")} onRemove={() => onRemoveAsset("digitalAssets", "CB")} />
            </ImageWrapper>

        </div>
    )

    const renderMetaTab = () => {
        return (
            <>
                <TextInput theme={theme} label={"Advertiser Name"} value={controller.name} onValueChange={controller.setName}/>
                <TextInput theme={theme} label={"Contact Name"} value={controller.contactName} onValueChange={controller.setContactName}/>
                <TextInput theme={theme} label={"Email Address"} value={controller.contactEmail} onValueChange={controller.setEmail}/>
                <TextInput theme={theme} label={"Phone Number"} value={controller.contactPhoneNumber} onValueChange={controller.setPhoneNumber}/>
            </>
        )
    }

    return (
        <>
            <Tabs theme={theme} items={tabs} activeIndex={state.activeTab} onTabClick={onTabSelect}>
                {renderTabBody()}
            </Tabs>
            {
                state.showMediaPicker &&
                <SlidingModal size={"medium"} theme={theme} visible={state.showMediaPicker} onCancel={onCancelAddAsset} onOk={onUploadAsset}>
                    <AssetUploader onFileUpload={setAsset} />
                </SlidingModal>
            }
            {
                state.showLogoPicker &&
                <SlidingModal size={"medium"} theme={theme} visible={state.showLogoPicker} onCancel={onCancelAddLogo} onOk={onUploadLogo}>
                    <AssetUploader onFileUpload={setLogo} />
                </SlidingModal>
            }
            {controller.isLoading && <Loader type={"bar"} theme={theme} message="Please wait..."/>}
        </>
    )
};

export {AdvertiserEditView};