import React from 'react';
import { EditableList, Label } from '@centrepointmedia/react-ui';
import { Recipes } from '../data';

const CategoryTabBody = ({theme}) => {
    const controller = Recipes.useContainer();
    const {recipe} = controller.model;

    const onAddTag = (tag) => controller.methods.update({tags: [...recipe.tags, ...tag.map(t => t.toLowerCase())]});

    const onRemoveTag = (index) => {
        const tags = [...recipe.tags].filter((_, idx) => idx !== index);
        controller.methods.update({tags});
    }
    
    return <>
        <Label text="Tags" theme={theme} />
        <EditableList theme={theme} iconName={"tags"} items={recipe.tags} onAdd={onAddTag} onRemove={onRemoveTag} />
    </>
}

const CategoryTab = {
    settings: {
        name: 'Tags',
        icon: 'tags'
    },
    component: CategoryTabBody
}

export {CategoryTab};