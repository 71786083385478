const { useReducer, useEffect } = require("react");
const { createContainer } = require("unstated-next");
const { Http, HttpActions } = require("../../../../core/http");

const Actions = {
    Load: 'RECIPES.LOAD',
    List: 'RECIPES.LIST',
    New: 'RECIPES.NEW',
    Update: 'RECIPES.UPDATE',
    DidSave: 'RECIPES.DIDSAVE',
    SetPage: 'RECIPESEARCH.SETPAGE',
    SetQuery: 'RECIPESEARCH.SETQUERY',
    SetSort: 'RECIPESEARCH.SETSORT',
    SetFilter: 'RECIPESEARCH.SETFILTER',
    Search: 'RECIPESEARCH.SEARCH',
    ToggleArchive: 'RECIPESEARCH.TOGGLEARCHIVE',
    Preview: 'RECIPESEARCH.PREVIEW',
    ResetPreview: 'RECIPESEARCH.RESETPREVIEW'
};

const RECIPE = {
    title: '',
    summary: '',
    servesMin: 2,
    servesMax: 4,
    socialImage: {
        url: ''
    },
    hero: [],
    method: [],
    content:[],
    ingredients: [],
    history: [],
    tags: [],
    advertiser: null,
    contentId: ''

}

const SEARCH_STATE = {
    searchText: '',
    page: 1,
    filters: [],
    results: {
        items: [],
        facets: [],
        total: 0
    },
    sort: {
        key: 'score',
        direction: 'asc'
    },
    viewArchived: false,
    previewHtml: ''
};

const INITIAL_STATE = {
    recipe: {...RECIPE},
    isDirty: false,
    didSave: false,
    previewHtml: ''
}

const reducer = (state = INITIAL_STATE, {type, payload}) => {
    switch(type) {
        case Actions.List:
            return {...state, recipes: payload};
        case Actions.Load:
            return {...state, recipe: {...payload.doc, contentId: payload.id}, history: [...payload.history], isDirty: false, didSave: false};
        case Actions.New: 
            return {...state, recipe: {...RECIPE}, isDirty: false, didSave: false};
        case Actions.Update:
            return {...state, recipe: {...state.recipe, ...payload}, isDirty: true, didSave: false};
        case Actions.DidSave:
            return {...state, isDirty: false, didSave: true};
        case Actions.Preview:
            return {...state, previewHtml: payload};
        case Actions.ResetPreview:
            return {...state, previewHtml: ''};
        default:
            return state;
    }
}

const dispatchFactory = (dispatch) => {
    const list = (payload) => dispatch({type: Actions.List, payload});
    const load = (payload) => dispatch({type: Actions.Load, payload});
    const create = () => dispatch({type: Actions.New});
    const update = (payload) => dispatch({type: Actions.Update, payload});
    const saved = () => dispatch({type: Actions.DidSave});

    const search = (payload) => dispatch({type: Actions.Search, payload});
    const setQuery = (text) => dispatch({type: Actions.SetQuery, payload: text});
    const setPage = (page) => dispatch({type: Actions.SetPage, payload: page});
    const setSort = (sort) => dispatch({type: Actions.SetSort, payload: sort});

    const toggleViewMode = () => dispatch({type: Actions.ToggleArchive});
    const preview = (html) => {
        dispatch({type: Actions.Preview, payload: html});
    }
    const resetPreview = () => dispatch({type: Actions.ResetPreview, payload: ""});

    return {
        list,
        load,
        create,
        update,
        saved,
        setQuery,
        setPage,
        setSort,
        search,
        toggleViewMode,
        preview,
        resetPreview
    }
}

const searchReducer = (state = SEARCH_STATE, {type, payload}) => {
    switch(type) {
        case Actions.SetQuery:
            return {...state, searchText: payload};
        case Actions.SetPage:
            return {...state, page: payload};
        case Actions.Search:
            return {...state, results: {...payload}};
        case Actions.ToggleArchive:
            return {...state, viewArchived: !state.viewArchived};
        case Actions.SetSort:
            return {...state, sort: {...payload}};
        case Actions.Preview:
                return {...state, previewHtml: payload};
        case Actions.ResetPreview:
                return {...state, previewHtml: ''};
        default:
            return state;
    }
}
const PAGE_SIZE = 20;
const useRecipeSearch = () => {
    const [state, dispatch] = useReducer(searchReducer, SEARCH_STATE);
    const http = Http.useContainer();
    const dispatcher = dispatchFactory(dispatch);

    useEffect(() => {
        search();
    }, []);

    useEffect(() => {
        if(state.page === 1) {
            search();
            return;
        }
        setPage(1);
    }, [state.searchText, state.sort, state.filters, state.viewArchived]);

    useEffect(() => {
        search();
    }, [state.page]);

    const setQuery = (text) => dispatcher.setQuery(text);
    const setPage = (page) => dispatcher.setPage(page);
    const setSort = (sort) => dispatcher.setSort(sort);

    const toggleViewMode = () => dispatcher.toggleViewMode();
    const publish = (id) => 
        http.startAsync()
        .then(_ => fetch('/api/v1/recipes/publish/' + id, {
            method: 'GET'
        }))
        .then(http.end)
        .then(_ => setTimeout(search, 500))
        .catch(http.error);
    
    const archive = (id) => 
        http.startAsync()
        .then(_ => fetch('/api/v1/recipes/' + id, {
            method: 'DELETE'
        }))
        .then(http.end)
        .then(_ => setTimeout(search, 500))
        .catch(http.error);

    const search = () =>
        http.startAsync()
        .then(_ => fetch('/api/v1/recipes/search',
        {
            method: 'POST',
            body: JSON.stringify({text: state.searchText, filters: state.filters, sort: state.sort, page: state.page, pageSize: PAGE_SIZE, published: !state.viewArchived}),
            headers: {
                'Content-Type': 'application/json'
            }
        }))
        .then(res => res.json())
        .then(dispatcher.search)
        .then(http.end)
        .catch(http.error);

    const preview = (id) => 
        http.startAsync()
        .then(_ => fetch(`/api/v1/recipes/preview/${id}`))
        .then(res => res.text())
        .then(dispatcher.preview)
        .then(http.end)
        .catch(http.error);

    const resetPreview = () => dispatcher.resetPreview();
    
    return {
        setQuery,
        setPage,
        search,
        setSort,
        toggleViewMode,
        publish,
        archive,
        isLoading: http.isLoading,
        error: http.error,
        ...state,
        preview,
        resetPreview
    }
}

const useRecipes = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const http = Http.useContainer();
    const dispatcher = dispatchFactory(dispatch);
    
    const load = (id) =>
        http.startAsync()
        .then(_ => fetch(`/api/v1/recipes/${id}`))
        .then(res => res.json())
        .then(dispatcher.load)
        .then(http.end)
        .catch(http.error);

    const loadVersion = (versionId) => {
        const payload = {
            doc: state.history.find(v => v.id === versionId),
            id: state.recipe.contentId,
            history: state.history
        };
        dispatcher.load(payload);
    }

    const create = () => dispatcher.create();
    const update = (delta) => dispatcher.update(delta);
    const save = () => 
        http.startAsync()
        .then(_ => fetch('/api/v1/recipes', {
            method: 'POST',
            body: JSON.stringify(state.recipe),
            headers: {
                'Content-Type': 'application/json'
            }
        }))
        .then(res => res.json())
        .then(dispatcher.load)
        .then(dispatcher.saved)
        .then(http.end)
        .catch(http.error);

    const reset = () => dispatcher.create();

    return {
        methods: {
            load,
            create,
            update,
            save,
            loadVersion,
            reset
        },
        isLoading: http.isLoading,
        error: http.error,
        model: state
    }
};

const Recipes = createContainer(useRecipes);
const RecipeSearch = createContainer(useRecipeSearch);
export {Recipes, RecipeSearch};