import React, {useState} from 'react';
import styled from 'styled-components';
import {
    ImagePicker,
    Pager,
    Panel,
    SlidingModal,
    useNotification,
    Loader
} from "@centrepointmedia/react-ui";
import { MediaToolbar } from '../components/media.toolbar';
import { MediaEdit } from './media.edit.view';
import {useMediaController} from './../data';
import { MediaCreate } from './media.create.view';

const Container = styled.div`
    display: flex;
    flex-direction:column;
`;
const MediaPage = ({theme, canAddImage}) => {
    const controller = useMediaController();
    const notificationManager = useNotification();

    const [state, setState] = useState({editingImage: null, createImage: {title: ''}, showCreateImage: false});
    const {selectedImage, results} = controller;
    const onClearSearch = () => controller.query('');
    const onSearch = (text) => controller.query(text);

    const onImageSelected = (images) => {
        if(images.length > 0) {
            controller.onSelectImage(images[0]);
        }
    }

    const onCancelEdit = () => controller.onSelectImage(null);
    const onSaveEdit = () => {
        if(controller.selectedImage && controller.selectedImage.title) {
            controller.onUpdateImage(controller.selectedImage);
        } else {
            notificationManager.add({message: "Title is a required field, media could not be saved", icon: "error"});
        }
    }

    const onEditIsValid = () => {
        console.log(controller.selectedImage);
        return !!(controller.selectedImage && controller.selectedImage.title.length > 0);

    }

    const onNewIsValid = () => {
        return true;
    }

    const onCancelNew = () => setState(s => ({...s, createImage: {title: ''}, showCreateImage: false}));
    const onSaveNew = () => {
        if(state.createImage.title && state.createImage.file) {
            controller.onCreateMedia(state.createImage);
        } else {
            notificationManager.add({message: "Title and file are required fields, media could not be saved", icon: "error"});
        }

        setState(s => ({...s, showCreateImage: false}));
    }

    const onImageEdited = (image) => controller.onEditImage(image);
    const onNewImageEdited = (image) => {
        setState(s => ({...s, createImage: {...image}}));
    }

    const onCreateMedia = () => {
        setState(s => ({...s, createImage: {file: {}, title: '', tags: []}, showCreateImage: true}));
    }

    const onImageRemoved = () => controller.refresh();

    return (
        <Panel theme={theme} body={() => 
            <Container>
                <MediaToolbar theme={theme} canAddImage={canAddImage} onClearSearch={onClearSearch} onSearch={onSearch} onNew={onCreateMedia} value={controller.searchText} />
                <ImagePicker theme={theme} maxSelect={1} onImageSelected={onImageSelected} images={results.images} />
                <Pager theme={theme} total={results.total} pageSize={20} start={controller.page} onNext={controller.onNextPage} onPrevious={controller.onPreviousPage} />
                
                {selectedImage !== null && <SlidingModal size={'medium'} theme={theme} visible={selectedImage !== null} onIsValid={onEditIsValid} onCancel={onCancelEdit} onOk={onSaveEdit}>
                    <MediaEdit theme={theme} onImageUpdated={onImageEdited} onClose={onCancelEdit} onImageRemoved={onImageRemoved}/>
                </SlidingModal>}
                {state.showCreateImage && <SlidingModal size={'medium'} theme={theme} visible={state.showCreateImage} onIsValid={onNewIsValid} onCancel={onCancelNew} onOk={onSaveNew}>
                    <MediaCreate theme={theme} onImageUpdated={onNewImageEdited}/>
                </SlidingModal>}
                {controller.httpStatus.isLoading && <Loader mode="modal" theme={theme} type="bar" message="Updating, please wait" />}
            </Container>
        }/>
    )
}

export {MediaPage};