import React, {useEffect, useState} from 'react';
import {
    ImagePicker,
    SlidingModal,
    Toolbar,
    ToolbarItemInput,
    ToolbarItem,
    Pager,
    Loader
} from "@centrepointmedia/react-ui";
import {useMediaController} from './../data';

const MediaPicker = ({theme, canAddImage, visible, onCancel, onOk, maxImageSelection}) => {
    const controller = useMediaController();
    const [state, setState] = useState({isVisible: visible});
    const onClearSearch = () => controller.query('');
    const onSearch = (text) => controller.query(text);

    useEffect(() => {
        if(visible) {
            controller.resetSearch();
        }
        setState(s => ({...s, isVisible: visible}));
    }, [visible]);

    const onImageSelected = (images) => {
        if(images.length === maxImageSelection && onOk) {
            clearSearch();
            onOk(images);
        }
    }

    const clearSearch = () => controller.query('');
    const okClick = () => {
        clearSearch();
        if(onOk) {
            onOk()
        }
    }
    return (
        <SlidingModal size={'medium'} title="Media Picker" theme={theme} visible={state.isVisible} onOk={okClick} onCancel={onCancel}>
            <>
                <Toolbar theme={theme}>
                    {canAddImage && <ToolbarItem iconName="plus">New image</ToolbarItem> }
                    <ToolbarItemInput isFullWidth={true} iconName="search" onClear={onClearSearch} onClick={onSearch} />
                </Toolbar>
                <ImagePicker theme={theme} maxSelect={maxImageSelection} onImageSelected={onImageSelected} images={controller.results.images} />
                <Pager theme={theme} total={controller.results.total} pageSize={20} start={controller.page} onNext={controller.onNextPage} onPrevious={controller.onPreviousPage} />
                {controller.httpStatus.isLoading && <Loader mode="inline" theme={theme} type="bar" message="Updating, please wait" />}
            </>
        </SlidingModal>
    );
};

export {MediaPicker};