const AgeRatings = [
    "U",
    "PG",
    "12A",
    "12",
    "15",
    "18",
    "R18"
].map((rating, idx) => ({text: rating, key: idx+1}));

export {AgeRatings};