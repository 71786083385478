import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Advertisers } from "../data/use.advertisers"
import {
    Panel,
    Toolbar, ToolbarItem, ToolbarItemInput,
    Table, TableColumn, TableColumnMultiLine,
    TableColumnSubtitle, TableColumnTitle, FaIcon, DropDown, Pager, Loader, SlidingModal, SideMenu, TextInput, Breadcrumb

} from "@centrepointmedia/react-ui";

const TableContainer = styled.div`
    margin-top: 20px;
    padding: 0 20px;
`;

const AccessoryWrapper = styled.div`
    text-align: right;
`;

const AdvertiserList = ({theme, selected, onSelect}) => {
    const [state, setState] = useState({selectedId: selected});
    const listing = Advertisers.useContainer();
    useEffect(() => {
        listing.load();
    }, []);

    const getAdvertiserDetails = (advertiser) => 
       `${advertiser.contactName}: ${advertiser.contactEmail}`;

    const onSelectRow = (row) => {
        setState(s => ({...s, selectedId: row._id}));
        onSelect(row);
    }
    const isSelected = (advertiser) => {
        return advertiser._id === (state.selectedId || selected);
    }

    return (
        <TableContainer>
            <Table theme={theme} name="advertisers" items={listing.advertisers} onRenderRow={(a, index) => (
                <TableColumnMultiLine key={`row-${index}`}>
                    <TableColumnTitle><strong>{a.name}</strong></TableColumnTitle>
                    <TableColumnSubtitle><span dangerouslySetInnerHTML={{__html: getAdvertiserDetails(a)}}></span></TableColumnSubtitle>
                </TableColumnMultiLine>
                
            )} onRenderAccessory={(row, index) => (
                <TableColumn>
                    <AccessoryWrapper>
                        <FaIcon onClick={() => onSelectRow(row, index)} name="check-circle" fontSize={1} sizeUnit={'rem'} style={isSelected(row) ? 'solid' : 'light'} label={isSelected(row) ? 'selected' : 'select'} />                            
                    </AccessoryWrapper>
                </TableColumn>
            )}  onRenderColumnHead={(row, index) => "Advertiser"}
            renderHeaders={true} columnCount={1}/>
        </TableContainer>
        );
}

export {AdvertiserList};