import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {
    Panel,
    Toolbar, ToolbarItem, ToolbarItemInput,
    Table, TableColumn, TableColumnMultiLine,
    TableColumnSubtitle, TableColumnTitle, FaIcon, DropDown, Pager, Loader, SlidingModal, SideMenu, TextInput, Breadcrumb

} from "@centrepointmedia/react-ui";
import {AdvertiserEditView} from "./advertiser.edit";
import { Advertiser } from "../data/use.advertiser";
import { Advertisers } from "../data/use.advertisers";
import {useHistory, useLocation} from 'react-router-dom';
import { AdvertiserList } from "./advertiser.list";

const TableContainer = styled.div`
    margin-top: 20px;
`;

const AccessoryWrapper = styled.div`
    text-align: right;
`;

const NoResults = styled.div`
    margin-top: 10px;
    padding: 20px;
    border-radius: 6px;
    background-color: ${props => props.background};

    p {
        color: ${props => props.color};
        font-size: .875rem;
    }
`;

const BreadcrumbWrapper = styled.div`
    padding: 5px 0;
    margin-bottom: 8px;
`;

const Container = styled.div`
    display: flex;
    margin: -25px -5px;
    height: calc(100vh - 51px);
    overflow: hidden;
`;

const INITIAL_STATE = {
    addingNewAdvertiser: false,
    addingCategoryAdvertiser: false,
    editingAdvertiser: false,
    rootMenu: [],
    createNewSection: false,
    newSection: "",
    selectedRoot: "",
    addNewCategory: false,
    newCategory: "",
    addSubCategory: false,
    subCategory: "",
    selectedCategory: "",
    route: [],
    breadcrumbs: [],
    selectedAdvertiserCategory: null
};
const AdvertiserCategoriesView = ({theme}) => {
    const [state, setState] = useState({...INITIAL_STATE});
    const controller = Advertiser.useContainer();
    const listing = Advertisers.useContainer();

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        listing.getRoots();
    }, []);

    useEffect(() => {
        const urlParts = location.pathname.split('/');
        urlParts.shift();
        urlParts.shift();
        urlParts.shift();
        setState(s => ({...s, route: urlParts}));
        listing.selectRoot(listing.roots.find(r => r._id === urlParts[urlParts.length-1]) || listing.selectedRoot.children.find(r => r._id === urlParts[urlParts.length-1]));
    }, [location]);

    useEffect(() => {
        breadcrumbs();
    }, [state.route]);

    useEffect(() => {
        setState(s => ({...s, addingCategoryAdvertiser: false, editingAdvertiser: false}));
    }, [listing.selectedRoot]);


    const onAddNewCategory = () => setState(s => ({...s, addNewCategory: true, newCategory: ""}));
    const onCancelNewCategory = () => setState(s => ({...s, addNewCategory: false}));
    const onSaveNewCategory = () => {
        onCancelNewCategory();
        listing.addCategory({name: state.newCategory, parent: listing.selectedRoot._id, type: "file"});
    } 
    
    const onAddNewSubCategory = (category) => setState(s => ({...s, addSubCategory: true, subCategory: "", selectedCategory: category}));
    const onCancelNewSubCategory = () => setState(s => ({...s, addSubCategory: false}));
    const onSaveNewSubCategory = () => {
        onCancelNewSubCategory();
        listing.addCategory({name: state.subCategory, parent: state.selectedCategory, type: "file"});
    }

    const breadcrumbs = () => {
        listing.loadBreadcrumbs(state.route).then(breadcrumbs => {

            setState(s => ({...s, breadcrumbs}));
        })
    }

    const onBreadcrumbClick = (item) => {
        const route = state.breadcrumbs.find(crumb => crumb.name === item.replace('/', ''));

        onSelectRoot(route);
    }

    const onAddAdvertiser = () => setState(s => ({...s, addingCategoryAdvertiser: true}));
    const onCancelAddCategoryAdvertiser = () => setState(s => ({...s, addingCategoryAdvertiser: false}));

    const onUpdateSubCategoryAdvertiser = (advertiser) => {
        listing.updateCategory({...state.selectedAdvertiserCategory, advertiser});
    }

    const onCancelNewItem = () => setState(s => ({...s, editingAdvertiser: false, selectedAdvertiserCategory: null}));
    const onSaveNewItem = () => {
        controller.save().then(listing.load);
        setState(s => ({...s, editingAdvertiser: false, addingNewAdvertiser: false}));
    }

    const onEditContent = (row, index) => {
        setState(s => ({...s, editingAdvertiser: true, selectedAdvertiserCategory: row}));
    }

    const onUpdateCategoryAdvertiser = (advertiser) => {
        listing.updateCategory({...listing.selectedRoot, advertiser});
    }

    const onSelectRoot = (root) => {
        if(root._id === -1) {
            setState(s => ({...s, createNewSection: true, newSection: ""}));
        } else {
            history.push(`/advertiser/categories/${root._id}`);
        }
    }

    const onViewCategory = (category) => {
        history.push(`/advertiser/categories/${state.route.join('/')}/${category._id}`);
    }

    const onCreateSection = () => setState(s => ({...s, createNewSection: true, newSection: ""}));
    const onCancelNewSection = () => setState(s => ({...s, createNewSection: false}));
    const onNewSection = () => {
        onCancelNewSection();
        listing.addSection(state.newSection);
    }

    const getAdvertiserDetails = (advertiser, displayName) => {
        if(!advertiser)
            return "No advertiser";

        const details = (displayName ? advertiser.name + ", " : "") + [advertiser.contactName, advertiser.contactEmailAddress]
        .filter(d => d && d.length > 0).join(', ');
        return details.length > 0 ? details : "No advertiser details";
    }

    const renderTable = () => {
        return (<TableContainer>
                <Table theme={theme} name="articles" items={listing.roots} onRenderRow={(a, index) => (
                    <TableColumnMultiLine key={`row-${index}`}>
                        <TableColumnTitle>{a.name}</TableColumnTitle>
                        <TableColumnSubtitle>{getAdvertiserDetails(a.advertiser, true)}</TableColumnSubtitle>
                    </TableColumnMultiLine>
                    
                )} onRenderAccessory={(row, index) => (
                    <TableColumn>
                        <AccessoryWrapper>
                            <FaIcon onClick={() => onEditContent(row, index)} name="pencil" fontSize={1} sizeUnit={'rem'} style='light' label="Edit advertiser" />
                        </AccessoryWrapper>
                    </TableColumn>
                )}  onRenderColumnHead={(row, index) => "Title"}
                renderHeaders={true} columnCount={1}/>
            </TableContainer>
        );
    }

    const renderCategoryAdvertiser = () => {
        return <TableContainer>
        <Table theme={theme} name="articles" items={[listing.selectedRoot.advertiser]} onRenderRow={(a, index) => (
            <TableColumnMultiLine key={`row-${index}`}>
                <TableColumnTitle>{a.name}</TableColumnTitle>
                <TableColumnSubtitle>{getAdvertiserDetails(a)}</TableColumnSubtitle>
            </TableColumnMultiLine>
            
        )} onRenderAccessory={(row, index) => (
            <TableColumn>
                <AccessoryWrapper>
                    <FaIcon onClick={onAddAdvertiser} name="pencil" fontSize={1} sizeUnit={'rem'} style='light' label="Replace advertiser" />                              
                </AccessoryWrapper>
            </TableColumn>
        )}  onRenderColumnHead={(row, index) => "Advertiser"}
        renderHeaders={true} columnCount={1}/>
    </TableContainer>
    }
    return (
        <Container>
            <Panel theme={theme} body={() => (
            <>
                {state.breadcrumbs.length > 1 && <BreadcrumbWrapper><Breadcrumb theme={theme} items={state.breadcrumbs.map(b => b.name)} onClick={onBreadcrumbClick} /></BreadcrumbWrapper>}
                <Toolbar>
                    <ToolbarItem iconName={"plus"} onClick={onCreateSection}>Add Category</ToolbarItem>
                </Toolbar>
                {renderTable()}
                <SlidingModal title="Advertiser Picker" theme={theme} visible={state.addingCategoryAdvertiser} size={"medium"} onOk={onCancelAddCategoryAdvertiser}>
                    <AdvertiserList theme={theme} selected={(listing.selectedRoot.advertiser || {_id: ''})._id} onSelect={onUpdateCategoryAdvertiser} />
                </SlidingModal>
                <SlidingModal theme={theme}  overflow={"hidden"} visible={state.addingNewAdvertiser} onOk={onSaveNewItem} onCancel={onCancelNewItem}>
                    <AdvertiserEditView theme={theme} />
                </SlidingModal>
                <SlidingModal title="Advertiser Picker" theme={theme} visible={state.editingAdvertiser} onOk={onCancelNewItem}>
                    <AdvertiserList theme={theme} selected={((state.selectedAdvertiserCategory || {advertiser: {_id: ""}}).advertiser || {_id: ""})._id} onSelect={onUpdateSubCategoryAdvertiser} />
                </SlidingModal>
                <SlidingModal size={"medium"} visible={state.createNewSection} overflow={"hidden"} theme={theme} onOk={onNewSection} onCancel={onCancelNewSection}>
                    <Panel embeddedWindow={false} theme={theme} body={() => <TextInput theme={theme} label="Section name" value={state.newSection} onValueChange={(e) => setState(s => ({...s, newSection: e}))}/>} />
                </SlidingModal>
                <SlidingModal size={"medium"} visible={state.addNewCategory} overflow={"hidden"} theme={theme} onOk={onSaveNewCategory} onCancel={onCancelNewCategory}>
                    <Panel embeddedWindow={false} theme={theme} body={() => <TextInput theme={theme} label="Category name" value={state.newCategory} onValueChange={(e) => setState(s => ({...s, newCategory: e}))}/>} />
                </SlidingModal>
                <SlidingModal size={"medium"} visible={state.addSubCategory} overflow={"hidden"} theme={theme} onOk={onSaveNewSubCategory} onCancel={onCancelNewSubCategory}>
                    <Panel embeddedWindow={false} theme={theme} body={() => <TextInput theme={theme} label="SubCategory name" value={state.subCategory} onValueChange={(e) => setState(s => ({...s, subCategory: e}))}/>} />
                </SlidingModal>
                {listing.isLoading && <Loader type={"bar"} theme={theme} message="Please wait..."/>}
            </>
        )} />
        </Container>
    )
};

export {AdvertiserCategoriesView};