import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {useHistory, Switch, Route, Redirect, useLocation} from 'react-router-dom';
import {
    SideMenu,
    ListPicker
} from '@centrepointmedia/react-ui';
import { ArticleListPage } from "./articles";
import { RecipeListPage } from "./recipes";
import { ReviewListPage } from "./reviews";

const Frame = styled.div`
    display: flex;
    width: 100%;
    background-color: ${props => props.theme.background};
`;

const articleTypes = [
    {
        text: 'Articles',
        icon: 'newspaper',
        key: 2,
        route: '/content/articles'
    },
    {
        text: 'Recipes',
        icon: 'hat-chef',
        key: 3,
        route: '/content/recipes'
    },
    {
        text: 'Reviews',
        icon: 'trophy-alt',
        key: 4,
        route: '/content/reviews'
    }
];

const sideMenu = [
    {
        text: "New item",
        selectDisabled: true,
        icon: 'file',
        button: {
            icon: 'plus',
            text: 'Create'
        },
        key: 1
    },
    ...articleTypes    
];

const createOptions = articleTypes.map(t => ({...t, text: t.text.substring(0, t.text.length-1)}));

const ContentPage = ({theme, context}) => {
    const [uiState, setUiState] = useState({addNewItem: false, isEditing: false});
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        sideMenu.forEach(m => {
            m.active = m.route === location.pathname;
        });
    }, []);

    useEffect(() => {
        sideMenu.forEach(m => {
            m.active = m.route === location.pathname;
        });
    }, [location.pathname]);

    useEffect(() => {
        const isEditing = checkLocationForEditPath();
        console.log(isEditing);
        if(isEditing) {
            setUiState(s => ({...s, isEditing: true}));
        } else {
            setUiState(s => ({...s, isEditing: false}));
        }
    }, [location]);

    const checkLocationForEditPath = () => {
        const segments = location.pathname.split('/').filter(s => s.length > 0);
        console.log(segments);
        return segments.length > 2 && segments[0] === 'content' && 
            (segments[2] === 'edit' || segments[2] === 'new');
    }

    const onSideMenuSelect = (item) => {
        if(item.key === 1) {
            setUiState(s => ({...s, addNewItem: true}));
            return;
        }

        history.push(item.route);
    }

    const onAddNewItemCancel = () => {
        setUiState(s => ({...s, addNewItem: false}));
    }

    const onCreateItemClick = (item) => {
        history.push(`${item.route}/new/1`);
        setUiState(s => ({...s, addNewItem: false}));
    }

    return (
        <Frame>
            {!uiState.isEditing && <SideMenu
                forceExpanded={true}
                items={sideMenu}
                theme={theme}
                onClick={onSideMenuSelect}
                canExpand={false}/>}
                    <Switch>
                        <Route path="/content/articles">
                            <ArticleListPage theme={theme} />
                        </Route>
                        <Route path="/content/recipes">
                            <RecipeListPage theme={theme} />
                        </Route>
                        <Route path="/content/reviews">
                            <ReviewListPage theme={theme} />
                        </Route>
                        <Redirect from="/content" to="/content/articles" />
                    </Switch>
                {uiState.addNewItem && 
                    <ListPicker
                        title="What type of object would you like to create?"
                        items={createOptions}
                        onCancel={onAddNewItemCancel}
                        onSelect={onCreateItemClick}
                        />
                }
        </Frame>
    )
}

export {ContentPage};