import React, {useReducer, useState} from 'react';
import { createContainer } from 'unstated-next';
import { Http } from '../../../core/http';

const Actions = {
    SetName: 'AV.SETNAME',
    SetContactName: 'AV.SETCONTACTNAME',
    SetCategoryName: 'AV.SETCATEGORYNAME',
    SetEmail: 'AV.SETEMAIL',
    SetPhoneNumber: 'AV.SETPHONE',
    SetLogo: 'AV.SETLOGO',
    SetUrl: 'AV.SETURL',
    SetAssets: 'AV.SETASSETS',
    AddAsset: 'AV.ADDASSET',
    RemoveAsset: 'AV.REMOVEASSET',
    RemoveAssetAtIndex: 'AV.REMOVEASSETATINDEX',
    Reset: 'AV.RESET',
    Load: 'AV.LOAD'

}

const INITIAL_STATE = {
    _id: '',
    name: '',
    contactName: '',
    contactEmail: '',
    contactPhoneNumber: '',
    printAssets: [],
    digitalAssets: [],
    assetUrl: ''
}

const reducer = (state = INITIAL_STATE, {type, payload}) => {

    const setProperty = (propName, propValue) => ({...state, [propName]: propValue});
    switch(type) {
        case Actions.SetName:
            return setProperty('name', payload);
        case Actions.SetCategoryName:
            return setProperty('categoryName', payload);
        case Actions.SetPhoneNumber:
            return setProperty('contactPhoneNumber', payload);
        case Actions.SetContactName:
            return setProperty('contactName', payload);
        case Actions.SetEmail:
            return setProperty('contactEmail', payload);
        case Actions.SetLogo:
            return setProperty('logo', payload);
        case Actions.SetUrl:
            return setProperty('assetUrl', payload);
        case Actions.SetAssets:
            return setProperty(payload.type, payload.assets);
        case Actions.AddAsset: {
            console.log(payload);
            const newState = setProperty(payload.type, [...state[payload.type], {...payload.asset, type: payload.name}]);
            console.log(newState);
            return newState;
        }
        case Actions.RemoveAsset: {
            const assets = [...state[payload.type]];
            const assetIdx = assets.findIndex(asset => asset.type === payload.name);
            if(assetIdx > -1) {
                assets.splice(assetIdx, 1);
            }

            return setProperty(payload.type, [...assets]);
        }
        case Actions.RemoveAssetAtIndex:
            return setProperty('assets', [...state.assets.filter((_, idx) => idx !== payload)]);
        case Actions.Reset: 
            return {...INITIAL_STATE};
        case Actions.Load:
            console.log(payload);
            return {...INITIAL_STATE, ...payload};
        default:
            return state;
        
    }
}

const useAdvertiser = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const http = Http.useContainer();

    const setName = (name) => dispatch({type: Actions.SetName, payload: name});
    const setEmail = (email) => dispatch({type: Actions.SetEmail, payload: email});
    const setContactName = (name) => dispatch({type: Actions.SetContactName, payload: name});
    const setCategoryName = (categoryName) => dispatch({type: Actions.SetCategoryName, payload: categoryName});
    const setPhoneNumber = (phoneNumber) => dispatch({type: Actions.SetPhoneNumber, payload: phoneNumber});
    const setLogo = (logo) => dispatch({type: Actions.SetLogo, payload: logo});
    const setUrl = (url) => dispatch({type: Actions.SetUrl, payload: url});
    const reset = () => dispatch({type: Actions.Reset});

    const postAsset = (asset, actionType, type, name) => {
        const data = new FormData() 
        data.append('file', asset);

        http.start();
        fetch('/api/v1/advertiser/asset', {
            method: 'POST',
            body: data
        })
        .then(response => response.json())
        .then(response => dispatch({type: actionType, payload: {asset: response, type, name}}))
        .then(http.end)
        .catch(http.error);
    }
    const uploadAsset = (asset, type, name) => postAsset(asset, Actions.AddAsset, type, name);

    const saveAsset = (asset, name) => {
        const data = new FormData()
        data.append('file', asset);

        http.start();
        return fetch('/api/v1/advertiser/asset', {
            method: 'POST',
            body: data
        })
            .then(response => response.json())
            .then(response => ({asset: response, name}))
            .then(res => {
                http.end();
                return res;
            })
            .catch(http.error);
    }

    const uploadLogo = (asset) => postAsset(asset, Actions.SetLogo);
    const removeAsset = (type, name) => dispatch({type: Actions.RemoveAsset, payload: {type, name}});

    const setAssets = (type, assets) => dispatch({type: Actions.SetAssets, payload: {type, assets}});
    const save = () => {
        return fetch('/api/v1/advertiser', {
            method: 'POST',
            body: JSON.stringify(state),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(response => dispatch({type: Actions.Load, payload: response}))
        .then(http.end)
        .catch(http.error);
    }

    const load = (advertiser) => dispatch({type: Actions.Load, payload: advertiser});

    return {
        ...state,
        setName,
        setEmail,
        setContactName,
        setCategoryName,
        setPhoneNumber,
        setUrl,
        setLogo,
        uploadAsset,
        saveAsset,
        uploadLogo,
        save,
        reset,
        load,
        removeAsset,
        setAssets,
        isLoading: http.isLoading,
        error: http.error
    }

}

const Advertiser = createContainer(useAdvertiser);
export {Advertiser};