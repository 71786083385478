import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {
    Panel,
    Toolbar, ToolbarItem, ToolbarItemInput,
    Table, TableColumn, TableColumnMultiLine,
    TableColumnSubtitle, TableColumnTitle, FaIcon, DropDown, Pager, Loader, SlidingModal

} from "@centrepointmedia/react-ui";
import { Advertisers } from "../data/use.advertisers";

const TableContainer = styled.div`
    margin-top: 20px;
`;

const AccessoryWrapper = styled.div`
    text-align: right;
`;

const AdvertiserPicker = ({theme, onSelect, selected}) => {
    const listing = Advertisers.useContainer();
    const [state, setState] = useState({selectedId: selected});
    useEffect(() => {
        listing.getTags();
    }, []);

    const onSelectRow = (row, index) => {
        setState(s => ({...s, selectedId: row._id}));
        if(onSelect) {
            onSelect(row);
        }
    }

    const constructBreadcrumb = (paths) => (<strong>
        {paths.join(" -> ")}
    </strong>)

    const isSelected = (advertiser) => {
        console.log(selected);
        return advertiser._id === (state.selectedId || selected);
    }

    const getAdvertiserDetails = (advertiser, displayName) => {
        if(!advertiser)
            return "No advertiser";

        const details = (displayName ? advertiser.name + ", " : "") + [advertiser.contactName, advertiser.contactEmailAddress]
        .filter(d => d && d.length > 0).join(', ');
        console.log(details);
        return details.length > 0 ? details : "No contact details";
    }

    return (
        <TableContainer>
            <Table theme={theme} name="advertisers" items={listing.tags} onRenderRow={(a, index) => (
                <TableColumnMultiLine key={`row-${index}`}>
                    <TableColumnTitle>{constructBreadcrumb(a.paths)}</TableColumnTitle>
                    <TableColumnSubtitle>{getAdvertiserDetails(a.advertiser, true)}</TableColumnSubtitle>
                </TableColumnMultiLine>
                
            )} onRenderAccessory={(row, index) => (
                <TableColumn>
                    <AccessoryWrapper>
                    <FaIcon onClick={() => onSelectRow(row, index)} name="check-circle" fontSize={1} sizeUnit={'rem'} style={isSelected(row) ? 'solid' : 'light'} label={isSelected(row) ? 'selected' : 'select'} />                            
                    </AccessoryWrapper>
                </TableColumn>
            )}  onRenderColumnHead={(row, index) => "Title"}
            renderHeaders={true} columnCount={1}/>
        </TableContainer>
        );
}

export {AdvertiserPicker};