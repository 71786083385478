import React, {Fragment, useState, useEffect} from "react";
import styled from "styled-components";
import {
    Panel,
    Toolbar, ToolbarItem, ToolbarItemInput,
    Table, TableColumn, TableColumnMultiLine,
    TableColumnSubtitle, TableColumnTitle, FaIcon, DropDown, Pager, Loader, SlidingModal

} from '@centrepointmedia/react-ui';
import { RecipeSearch } from "../data";

const TableContainer = styled.div`
    margin-top: 20px;
`;

const AccessoryWrapper = styled.div`
    text-align: right;
`;

const NoResults = styled.div`
    margin-top: 10px;
    padding: 20px;
    border-radius: 6px;
    background-color: ${props => props.background};

    p {
        color: ${props => props.color};
        font-size: .875rem;
    }
`;

const INITIAL_UI_STATE = {
    viewing: 'published',
    sortBy: {
        alpha: {
            key: 'alpha',
            direction: 'asc',
            active: false
        },
        date: {
            key: 'date',
            direction: 'desc',
            active: false
        },
        score: {
            key: 'score',
            direction: 'asc',
            active: true
        }
    },
    isSearching: false,
    results: {
        all: [],
        filtered: [],
        selectedFilters: [],
        facets: []
    },
    searchText: '',
    filters: [],
    page: 1
}

const sortIcon = ({key, direction}) => {
    if(key === 'alpha')
        return direction === 'asc' ? 'sort-alpha-down' : 'sort-alpha-up';

    if(key === 'date')
        return direction === 'asc' ? 'sort-amount-up-alt' : 'sort-amount-down-alt';

    if(key === 'score') 
        return 'sort-numeric-down';
}

const toggleDirection = (direction) => direction === 'asc' ? 'desc' : 'asc';

const RecipeListView = ({theme, type, onEdit}) => {
    const [uiState, setUiState] = useState(INITIAL_UI_STATE);
    const controller = RecipeSearch.useContainer();

    useEffect(() => {
        controller.setSort(activeSortby());
    }, [uiState.sortBy]);
    
    const onEditContent = (item) => {
        if(onEdit) {
            onEdit(item._id);
        }
    }

    const onPreviewContent = (item) => {
        controller.preview(item._id);
    }

    const onClosePreview = () => controller.resetPreview();

    const search = () => {
        controller.search();
    }

    const onArchive = (item) => controller.viewArchived ? controller.publish(item._id) : controller.archive(item._id);

    const onSortClick = ({key, direction, active}) => {
        const newSortBy = {...uiState.sortBy};
        Object.keys(newSortBy).forEach(k => newSortBy[k].active = false);
        setUiState(s => ({...s, sortBy: {...newSortBy, [key]: {key, direction: active ? toggleDirection(direction) : direction, active: true}}}))
        
    }

    const activeSortby = () => Object.keys(uiState.sortBy).map(k => uiState.sortBy[k]).find(o => o.active);

    const onSortByAlpha = () => {
        onSortClick(uiState.sortBy.alpha);
    }

    const onSortByScore = () => {
        onSortClick(uiState.sortBy.score);
    }

    const onSortByDate = () => {
        onSortClick(uiState.sortBy.date);
    }
    const onToggleViewType = () => {
        controller.toggleViewMode();
    }

    const onSearch = (text) => {
        console.log('set search text');
        controller.setQuery(text);
    };

    const onFilterSelect = (filter) => {
        if(filter.filter === 'All') {
            controller.resetFilter();
            return;
        }

        controller.setFilter(filter);
    }

    console.log(controller.previewHtml);

    //const facets = [{text: 'All', key: 0, filter: 'All'}, ...controller.results.facets.map((f, idx) => ({text: `${f.key} (${f.doc_count})`, key: idx+ 1, filter: f.key}))];
    const tags = (item, cap) => 
        (item.tags || []).length >= cap ? item.tags.filter((_, idx) => idx < cap).join(', ') + ((item.tags.length - cap > 0) ? ` ...+${item.tags.length - cap} more` : '') : (item.tags || []).join(', ');
    
    
    const onNextPage = () => controller.setPage(controller.page+1);
    const onPreviousPage = () => controller.setPage(controller.page - 1);
    const renderTable = () => {
        const {items} = controller.results;
        const background = theme.color5;
        return items.length === 0 ?
            <NoResults background={background}>
                <p color={theme.textColor}>No results could be found matching your query</p>
            </NoResults> :
        (
            <TableContainer>
                <Table theme={theme} name="articles" items={items} onRenderRow={(a, index) => (
                    <TableColumnMultiLine key={`row-${index}`}>
                        <TableColumnTitle>{a.title}</TableColumnTitle>
                        <TableColumnSubtitle>{tags(a, 5)}</TableColumnSubtitle>
                    </TableColumnMultiLine>
                    
                )} onRenderAccessory={(row, index) => (
                    <TableColumn>
                        <AccessoryWrapper>
                            <FaIcon onClick={() => onPreviewContent(row)} name="file-search" fontSize={1} sizeUnit={'rem'} style='light' label="Preview" />
                            <FaIcon onClick={() => onEditContent(row)} name="pencil" fontSize={1} sizeUnit={'rem'} style='light' label="Edit" />
                            <FaIcon onClick={() => onArchive(row)} name={controller.viewArchived ? 'upload' : 'trash-alt'} fontSize={1} sizeUnit={'rem'} style='light' label={controller.viewArchived ? 'Publish' : 'Archive'} />
                        </AccessoryWrapper>
                    </TableColumn>
                )}  onRenderColumnHead={(row, index) => "Title"}
                renderHeaders={true} columnCount={1}/>
            </TableContainer>
        )
    }

    return <Panel theme={theme} body={() =>
        <Fragment>
            <Toolbar theme={theme}>
                <ToolbarItem iconName="filter" active={controller.viewArchived} onClick={onToggleViewType}>View archived</ToolbarItem>
                <ToolbarItem iconName="sort-numeric-down" active={uiState.sortBy.score.active} onClick={onSortByScore}>Sort by relevance</ToolbarItem>
                <ToolbarItem iconName={sortIcon(uiState.sortBy.alpha)} active={uiState.sortBy.alpha.active} onClick={onSortByAlpha}>Sort by name</ToolbarItem>
                <ToolbarItem iconName={sortIcon(uiState.sortBy.date)} active={uiState.sortBy.date.active} onClick={onSortByDate}>Sort by date</ToolbarItem>
                <ToolbarItemInput iconName="search" onClear={() => onSearch('')} onClick={onSearch} />
                <ToolbarItem iconName="sync-alt" onClick={controller.search}>Reload</ToolbarItem>
            </Toolbar>
            {controller.results.items && renderTable()}
            <Pager theme={theme} total={controller.results.total} pageSize={20} start={controller.page} onNext={onNextPage} onPrevious={onPreviousPage} />
            {controller.isLoading && <Loader type="bar" theme={theme} message="Loading, please wait..." mode="modal"/>}
            <SlidingModal size={'medium'} theme={theme} visible={controller.previewHtml.length > 0} onCancel={onClosePreview} onOk={onClosePreview}>
                <div dangerouslySetInnerHTML={{__html: controller.previewHtml}}></div>
            </SlidingModal>
        </Fragment>
    } />
}

export {RecipeListView};