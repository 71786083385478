import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {ContentApp} from "./content";
import { MediaApp } from "./media";
import { AdvertiserApp } from "./advertisers";

const AppManager = () => {
    const state = {
        selectedAppIndex: 0,
        apps: [ContentApp, MediaApp, AdvertiserApp],
        pathname: ''
    }

    const updatePathName = (pathname) => state.pathname = pathname;

    const menuItems = () => state
        .apps
        .map(app => app.sideMenu)
        .map((menu, index) =>
            ({...menu, key: index, active: (state.pathname.startsWith(menu.route) || state.pathname === '/' && index === 0)}));

    const renderRoute = ({theme, context}) => {
        return (
            <Switch>
                {state.apps.map((app, index) => (
                    <Route key={`route-${index}`} path={app.route}>
                        {app.render({theme, context})}
                    </Route>
                ))}
                <Redirect from="/" to={state.apps[0].route} />
            </Switch>
        )

    }

    return {
        menuItems, renderRoute, updatePathName
    }
}

export {AppManager};