import React, {useEffect} from "react";
import { useHistory, Switch, Route, useLocation, Redirect } from "react-router-dom";
import {AdvertiserCategoriesView, AdvertisersView} from "./views";
import {
    SideMenu,
    Panel
} from '@centrepointmedia/react-ui';

const subMenu = [
    {
        text: 'Advertisers',
        icon: 'ad',
        key: 1,
        route: '/advertiser/list'
    },
    {
        text: 'Categories',
        icon: 'tags',
        key: 2,
        route: '/advertiser/categories'
    }  
];

const AdvertiserRoot = ({theme, context}) => {
    const route = '/advertiser';
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        subMenu.forEach(m => {
            m.active = location.pathname === m.route || (location.pathname === route && m.text === 'Categories');
        });
    }, []);

    useEffect(() => {

        subMenu.forEach(m => {
            m.active = location.pathname === m.route || (location.pathname === route && m.text === 'Categories');
        });
    }, [location.pathname]);

    const onSelectRoute = (item) => {
        history.push(item.route);
    }

    return <>
        <SideMenu theme={theme} items={subMenu} forceExpanded={true} onClick={onSelectRoute} />
        <Panel theme={theme} noPadding={true} body={() => (
            <>
                <Switch>
                    <Route path="/advertiser/categories">
                        <AdvertiserCategoriesView theme={theme} />
                    </Route>
                    <Route path="/advertiser/categories/:id">
                        <AdvertiserCategoriesView theme={theme} />
                    </Route>
                    <Route path="/advertiser/list">
                        <AdvertisersView theme={theme} />
                    </Route>
                    <Redirect from="/advertiser" to="/advertiser/categories" />
                </Switch>
            </>
        )} />
    </>
}

export {AdvertiserRoot};