import React from 'react';
import {useLocation} from 'react-router-dom';

const useCurrentRoute = () => {
    const location = useLocation();
    const segments = () => location.pathname.split('/');
    const segment = (index) => segments[index];
    const lastSegment = () => {
        const parts = segments();
        return parts[parts.length-1];
    }

    const makeSegment = (name) => `/${name}/`;
    const containsSegment = (name) => 
        segments().some(s => (makeSegment(s).match(new RegExp(makeSegment(name),'gmi')) || []).length > 0);

    return {
        containsSegment,
        lastSegment,
        segment,
        segments,
        location
    }

}

export {useCurrentRoute};