import React, {useEffect} from 'react';
import {selectAll} from "./selector";
import {useDispatch, useSelector} from "react-redux";
import {MediaActions} from "./actions";


const useMedia = () => {
    const state = useSelector(selectAll);
    const dispatch = useDispatch();
    const actions = MediaActions(dispatch);
    return [state, actions];
}

const useMediaController = () => {
    const [state, actions] = useMedia();
    useEffect(() => {
        search();
    }, [state.searchText, state.page]);

    const search = () => actions.search({text: state.searchText, page: state.page, pageSize: state.pageSize});
    const query = (text) => actions.setQuery(text);
    const onNextPage = () => actions.nextPage();
    const onPreviousPage = () => actions.previousPage();
    const onSelectImage = (img) => actions.setImage(img);
    const onUpdateImage = (img) => actions.updateImage(img);
    const onEditImage = (img) => actions.editImage(img);
    const onCreateMedia = (img) => actions.createImage(img);
    const resetSearch = () => actions.resetSearch();
    const reportUsage = (id) => actions.reportUsage({id});
    const removeImageFromResults = (id) => actions.removeImageFromResults(id);

    const controller = {
        resetSearch,
        query,
        reportUsage,
        onNextPage,
        onPreviousPage,
        onSelectImage,
        onUpdateImage,
        onCreateMedia,
        onEditImage,
        refresh: () => search(),
        removeImageFromResults,
        ...state
    };

    console.log(state)

    return controller;
}

export {useMediaController};