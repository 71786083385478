import React from "react";
import {ContentPage} from "./page";

const App = () => {
    const route = '/content';
    const sideMenu = {text: 'Content', icon: 'file', route}

    const render = ({theme, context}) => <ContentPage theme={theme} context={context} />

    return {
        route,
        sideMenu,
        render
    };
}

const ContentApp = App();
export {ContentApp};