const primaryBk = '#264653';
const primary = '#4FBAE8';
const secondary = '#f08a70';

const primaryBkHighlight = '#5C747E';
const secondaryBk = '#E9C46A';
const secondaryBkHighlight = '#EED38F';

const white = '#FFFFFF';
const lightGrey = '#E2E2E2';
const blue = '#004EBF';
const royalBlue = '#004393';
const darkGrey = '#141414';
const black = '#000000';

const red = '#BF004E';
const red2 = '#930043';

const green = '#00BF11';
const green2 = '#009306';

const purple = '#BF00AE';
const purple2 = '#93008D';

const brown = '#BF1100';
const brown2 = '#930600';


const gradient1 = 'linear-gradient(135deg, rgba(0,78,191,1) 50%, rgba(0,67,147,1) 100%)';
const gradient2 = 'linear-gradient(135deg, #BF004E 50%, #930043 100%)';
const gradient3 = 'linear-gradient(135deg, #00BF11 50%, #009306 100%)';

const gradient4 = 'linear-gradient(135deg, #68F47F, 50%, #4EB75F 100%)';
const gradient5 = 'linear-gradient(135deg, #FBCE5E, 50%, #DBA623 100%)';

const whiteGradient = 'linear-gradient(135deg, #FFFFFF 50%, #FBFBFB 100%)';

const dark0 = '#1D3540';
const dark1 = '#213D49';
const dark2 = '#264653';
const dark3 = '#415D69';
const dark4 = '#5C747E';
const dark5 = '#778B94';
const dark6 = '#93A3A9';

const lightDisabled = '#809593';
const light0 = '#717171';
const light1 = '#8D8D8D';
const light2 = '#A9A9A9';
const light3 = '#C6C6C6';
const light4 = lightGrey;
const light5 = '#E1F1EF';
const light6 = white;


const customTheme = {
    'colorA': blue,
    'colorB': red,
    'colorC': green,
    'colorD': purple,
    'colorE': brown,
    'color0': light0,
    'color1': light1,
    'color2': light2,
    'color3': light3,
    'color4': light4,
    'color5': light5,
    'color6': light6,
    'gradient1': gradient1,
    'gradient2': gradient2,
    'gradient3': gradient3,
    'gradient4': gradient4,
    'gradient5': gradient5,
    'bn__primary_bc': gradient1,
    'bn__primary-disabled_bc': light3,
    'bn__primary-disabled_c': lightDisabled,
    'bn__secondary-disabled_bc': light3,
    'bn__secondary-disabled_c': lightDisabled,
    'bn__primary_br': primary,
    'bn__secondary_br': secondary,
    'bn__secondary_bc':gradient3,
    'bn__alt_c': black,
    'bn__primary_c':white,
    'bn__secondary_c': white,
    'bn__cancel_bc': white,
    'bn_br': light1,
    'mu__tp_bc': light6,
    'mu__tp_br': light4,
    'mu__tp_c': black,
    'mu__se_bc': light6,
    'mu__se_br': light4,
    'mu__se-alt_bc': light6,
    'mu__se-highlight_bc': gradient2,
    'mu__se-highlight_c': white,
    'mu__se_c': black,
    'mu__se-active_bc': gradient1,
    'mu__se-collapsed-full-active_bc': light5,
    'mu__se-collapsed-full-highlight_bc': light5,
    'mu__se-collapsed-active_bc': blue,
    'mu__se-collapsed-highlight_bc': red,
    'mu__se-active_c': white,
    'mu__tr_c': white,
    'mu__tr_bc': blue,
    'mu__tr-item_bc': royalBlue,
    'mu__tr-item-hover_bc': royalBlue,
    'mu__tr_br': royalBlue,
    'er_bc': light4,
    'er_br': light2,
    'er_c': black,
    'er-highlight_c': 'rgba(240,138,112,1)',
    'er-highlight_c-dark': 'rgba(240,138,112,1)',
    'in_bc': dark1,
    'in-highlight_bc': dark4,
    'pl__toolbar_bc': light4,
    'pl__canvas_bc': light4,
    'pl__footer_bc': light6,
    'pl__toolbar_c': dark1,
    'pl__canvas_c': dark1,
    'pl__footer_c': dark1,
    'tb__tab_bc': light3,
    'tb__tab-active_bc': white,
    'tb__tab_br': light4,
    'tb__panel_bc': white,
    'tb__tab_c': lightDisabled,
    'tb__tab-active_c': black,
    'tb__tab-notification_bc': light2,
    'tb__tab-notification_c': light0,
    'tb__tab-notification-active_bc': light4,
    'tb__tab-notification-active_c': black,
    'text_c': dark1,
    'it__tt_br': light0,
    'it__tt_bc': white,
    'it__tt_c': black,
    'it__if_br': light0,
    'it__if_bc': light6,
    'it__if-highlight_bc': gradient1,
    'it__if-close_bc': gradient3,
    'it__if-highlight_c': white,
    'it__if_c': black,
    'it__rt_br': light0,
    'it__rt_bc': white,
    'cl_bc': light4,
    'cl_br': lightDisabled,
    'cl_c': black,
    'cl__tr_bc': light0,
    'lr_bc': `linear-gradient(135deg, ${light3} 50%, ${light5} 50%);`,
    'lr-hover_bc': gradient1,
    'lr-hover_c': white,
    'ww_bc': light4,
    'sr__body_bc': '#F1F1F1',
    'sr__toolbar_bc': light6,
    'sr__footer_bc': light6,
    'sr__toolbar_c': dark1,
    'sr__overlay_bc': 'rgba(0,0,0,0.75)',
    'cd_bc': white,
    'cd_c': black,
    'cd__rn_bc': gradient1,
    'cd__rn_c': white,
    'oy_bc': 'rgba(0,0,0,0.65)',
    'te_br': light4,
    'te_bc': blue,
    'te__cell_bc': light6,
    'te__header-c': light6,
    'te_c': black,
    'primary': primary,
    'secondary': secondary,
    'pr_br': light1,
    'dd_bc': light6,
    'dd_c': black,
    'dd_br': light2,
    'dd-highlight_bc': light2,
    'ml__nn_bc': gradient1,
    'ml__nn_c': white,
    'ml__nn-icon_c': secondary,
    'ml__nn-success_bc': gradient4,
    'ml__nn-error_bc': gradient3,
    'ml__nn-info_bc': gradient1,
    'ml__nn-warning_bc': gradient5,
    'bb_bc': light6,
    'bb_br': light2,
    'bb_c': black,
    'dp_c': black,
    'dp__active_c': white,
    'dp_bc': blue,
}

export {customTheme};