const MediaApi = () => {
    const search = async ({text, page, pageSize}) => {
        const response = await fetch('/api/v1/media/search',
            {
                method: 'POST',
                body: JSON.stringify({text, page, pageSize}),
                headers: {
                    'Content-Type': 'application/json'
                }});
        return response.json();
    }

    const reportUsage = (id) => fetch(`/api/v1/media/${id}`)
        .then(res => res.json());

    const update = async(image) => {
        const response = await fetch('/api/v1/media', 
        {
            method: 'POST',
            body: JSON.stringify(image),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    const create = async (img) => {
        const data = new FormData() 
        data.append('file', img.file);
        data.append('title', img.title);
        data.append('tags', img.tags);
        const response = await fetch('/api/v1/media/new', {
            method: 'POST',
            body: data
        });
        return response.json();
    }

    return {search, update, create, reportUsage};
};

export {MediaApi};