import React, {Fragment, useState, useEffect} from "react";
import styled from "styled-components";
import { Prompt, useHistory } from 'react-router-dom';
import { Tabs, Editor, Breadcrumb, Loader, useNotification, SlidingModal } from "@centrepointmedia/react-ui";
import { MetaTab } from "../tabs/meta.tab";
import { ContentTab } from "../tabs/content.tab";
import { HistoryTab } from "../tabs/history.tab";
import { Recipes } from "../data";
import { Http } from "../../../../core/http";
import { useCurrentRoute } from "../../../../core/router/use.current.route";
import { CategoryTab } from "../tabs/category.tab";
import { AdvertiserTab } from "../tabs/advertiser.tab";

const Form = styled.div`
    padding: 1rem 2rem;
    margin: 20px -20px;
    background: ${props => props.background};
`;

const Container = styled.div`
    height: calc(100vh - 106px);
    width: 100%;
`;

const PreviewContainer = styled.div`
    color: ${props => props.theme.textColor};

    article {
        blockquote {
            border-left:8px solid ${props => props.theme.textColor};
            &::before {
                color: ${props => props.theme.textColor};
            }
        }
    }
`;

const tabs = [MetaTab, ContentTab, CategoryTab, AdvertiserTab, HistoryTab]
    .map((tab, idx) => ({...tab, settings: {...tab.settings, key: idx}}));


const RecipeEditView = ({theme}) => {
    const [state, setState] = useState({activeTab: 0, httpLoading: false});
    const controller = Recipes.useContainer();
    const http = Http.useContainer();
    const notificationsManger = useNotification();
    const currentRoute = useCurrentRoute();

    console.log(currentRoute);

    const history = useHistory();

    useEffect(() => {
        if(controller.didSave) {
            notificationsManger.add({message: 'Successfully saved article', icon: 'success'});
        }
    }, [controller.didSave]);

    useEffect(() => {
        if(currentRoute.containsSegment("edit")){
            const contentId = currentRoute.lastSegment();
            controller.methods.load(contentId);
        } else {
            controller.methods.reset();
        }
    }, []);


    const renderTab = () => {
        const TabBody = tabs[state.activeTab].component;
        return TabBody ? <TabBody controller={controller} theme={theme} /> : null;
    }

    const onClosePreview = () => controller.resetPreview();

    const onTabClick = (index) => {
        setState(s => ({...s, activeTab: index}));
    }

    const onBreadcrumbClick = (route) => {
        history.push(route.toLowerCase());
    }

    const onEditorSave = () => controller.methods.save();
    const onEditorPreview = () => controller.methods.preview();

    return <Container>
        <Prompt message={"Are you sure you wish to leave this page"} when={controller.model.isDirty} />
        <Editor theme={theme} onSave={onEditorSave} onPreview={onEditorPreview}>
            <Breadcrumb items={['Content', 'Recipes', controller.model.recipe.title || 'New']} theme={theme} onClick={onBreadcrumbClick} />
            <Tabs items={tabs.map(t => t.settings)} theme={theme} activeIndex={0} onTabClick={onTabClick}>
                <Form background={theme.color6}>
                    {renderTab()}
                </Form>
            </Tabs>
            {http.isLoading && <Loader type={"bar"} theme={theme} mode="modal" message="Please wait..." />}
        </Editor> 
        <SlidingModal size={'medium'} theme={theme} visible={controller.model.previewHtml.length > 0} onCancel={onClosePreview} onOk={onClosePreview}>
            <PreviewContainer theme={theme} dangerouslySetInnerHTML={{__html: controller.model.previewHtml}}></PreviewContainer>
        </SlidingModal>
        
    </Container>
}

export {RecipeEditView};