import { MediaApi } from "./http";

const ActionTypes = {
    selectImage: "Media.Select.Image",
    httpError: "Media.Http.Error",
    search: {
        query: '',
        start: "Media.Search.Start",
        end: "Media.Search.End",
        nextPage: "Media.Search.NextPage",
        previousPage: "Media.Search.PreviousPage",
        reset: "Media.Search.Reset"
    },
    crud: {
        start: "Media.Crud.Start",
        end: "Media.Crud.End",
        edit: "Media.Crud.Edit",
        canRemove: "Media.Crud.CanRemove",
        remove: "Media.Crud.Remove"
    }
    
}

const MediaActions = (dispatch) => {
    const api = MediaApi();
    const resultsFromHttp = (res) => ({ images:res.items.map(i => ({...i, id: i.identifier})) || [], total: res.total});

    const reportUsage = ({id}) => {
        api.reportUsage(id).then(res => res.map(r => r.title))
            .then(res => {
                dispatch({type: ActionTypes.crud.canRemove, payload: res});
            }).catch(err => dispatch({type: ActionTypes.httpError, payload: err}));
    }

    const search = ({text, page, pageSize}) => {
        dispatch({type: ActionTypes.search.start});
        api.search({text, page, pageSize}).then(res => {
            const payload = resultsFromHttp(res);
            dispatch({type: ActionTypes.search.end, payload: {...payload, images: payload.images.map(i => {
                const urlParts = (i.url || "").split("/");
                if(urlParts.length === 0)
                    return i;
                const imageName = urlParts[urlParts.length-1];
                i.url = `https://media.freefeatures.co.uk/api/v1/images/${imageName}`;
                return i;
            })}});
        })
        .catch(err => dispatch({type: ActionTypes.httpError, payload: err}));
    }

    const updateImage = (img) => {
        dispatch({type: ActionTypes.crud.start});
        api.update(img).then(res => {
            dispatch({type: ActionTypes.crud.end, payload: res});
        });
    }

    const editImage = (img) => dispatch({type: ActionTypes.crud.edit, payload: img});

    const createImage = (img) => {
        dispatch({type: ActionTypes.crud.start});
        api.create(img).then(res => {
            console.log(res);
            dispatch({type: ActionTypes.crud.end, payload: res});
        })
        .catch(err => dispatch({type: ActionTypes.httpError, payload: err}));
    }

    const nextPage = () => dispatch({type: ActionTypes.search.nextPage});
    const previousPage = () => dispatch({type: ActionTypes.search.previousPage});

    const setQuery = (query) => dispatch({type: ActionTypes.search.query, payload: query});
    const setImage = (image) => dispatch({type: ActionTypes.selectImage, payload: image});

    const resetSearch = () => dispatch({type: ActionTypes.search.reset});

    const removeImageFromResults = (id) => dispatch({type: ActionTypes.crud.remove, payload: id});

    return {search, nextPage, removeImageFromResults, reportUsage, previousPage, setQuery, setImage, updateImage, createImage, resetSearch, editImage };
}

export {ActionTypes, MediaActions}