import React, {useReducer, useState} from 'react';
import { createContainer } from 'unstated-next';
import { Http } from '../../../../core/http';
import { ArticleApi } from './article.api';
const INITIAL_STATE = {
    model: {
        title: '',
        summary: '',
        socialImage: {
            url: ''
        },
        controls: [],
        hero: [],
        content: '',
        contentId: '',
        tags: []
    },
    history: [],
    isDirty: false,
    didSave: false,
    previewHtml: ''
};

const ArticleEditActions = {
    Update: 'AEA.UPDATE',
    New: 'AEA.NEW',
    Load: 'AEA.LOAD',
    DidSave: 'AEA.DIDSAVE',
    Preview: 'AEA.PREVIEW',
    ResetPreview: 'AEA.RESETPREVIEW'
};

const articleEditReducer = (state, {type, payload}) => {
    switch(type) {
        case ArticleEditActions.Update:
            return {...state, isDirty: true, model: {...state.model, ...payload}};
        case ArticleEditActions.New:
            return {...INITIAL_STATE};
        case ArticleEditActions.Load:
            return {...INITIAL_STATE, model: {...payload.doc, contentId: payload.id}, history: [...payload.history]};
        case ArticleEditActions.DidSave:
            return {...state, didSave: payload};
        case ArticleEditActions.Preview:
            console.log(payload);
            return {...state, previewHtml: payload};
        case ArticleEditActions.ResetPreview:
            return {...state, previewHtml: ''};
        default:
            return state;
    }
}
const useArticleEdit = (initialState = INITIAL_STATE) => {
    const [state, dispatch] = useReducer(articleEditReducer, initialState);
    const http = Http.useContainer();

    const articleApi = ArticleApi();

    const update = (model) => dispatch({type: ArticleEditActions.Update, payload: model});
    const save = () => {
        http.start()
        dispatch({type: ArticleEditActions.DidSave, payload: false});
        articleApi.save({...state.model}).then(res => {
            console.log(res);
            dispatch({type: ArticleEditActions.Load, payload: res});
            dispatch({type: ArticleEditActions.DidSave, payload: true});
            http.end();
        })
        .catch(err => http.error(err));
    }

    const preview = () => {
        http.start();
        console.log('START PREVIEW');
        articleApi.preview({...state.model}).then(res => {
            http.end();
            console.log(res);
            dispatch({type: ArticleEditActions.Preview, payload: res});
        })
        .catch(http.error);
    }

    const resetPreview = () => dispatch({type: ArticleEditActions.ResetPreview});

    const loadVersion = (versionId) => {
        const payload = {
            doc: state.history.find(v => v.id === versionId),
            id: state.model.contentId,
            history: state.history
        };
        dispatch({type: ArticleEditActions.Load, payload});
    }

    const load = (id) => {
        http.start();
        articleApi.article(id).then(res => {
            console.log(res);
            dispatch({type: ArticleEditActions.Load, payload: res});
            http.end();
        })
        .catch(err => http.error(err));
    }

    const reset = () => dispatch({type: ArticleEditActions.New});

    return {
        model: state.model,
        history: state.history,
        isDirty: state.isDirty,
        didSave: state.didSave,
        update,
        save,
        load,
        loadVersion,
        reset,
        preview,
        previewHtml: state.previewHtml,
        resetPreview
    };
}

const ArticleEdit = createContainer(useArticleEdit);

export {ArticleEdit};
