import React from "react";
import { ControlsView } from "./../components/controls.component";

const articleContentDescription = "Use this section to add content to the review, by using \"controls\" it makes it easier to inject advertising and rearrange the review content.";
const heroDescription = "Use this section to add a hero to the review."
const ContentTabBody = ({theme}) => {
    return <>
    <ControlsView
        theme={theme}
        propKey="hero"
        label="Hero"
        max={1}
        filter={['image', 'video']}
        description={heroDescription}/>

    <ControlsView
        theme={theme}
        propKey="controls"
        label="Review Content"
        description={articleContentDescription}/>
    </>
}

const ContentTab = {
    settings: {
        name: 'Content',
        icon: 'newspaper'
    },
    component: ContentTabBody
}

export {ContentTab};