import React from 'react';
import {useHistory, Switch, Route} from 'react-router-dom';
import { ReviewEditView, ReviewListView } from './views';

const ReviewListPage = ({theme}) => {
    const history = useHistory();
    const onEditContent = (id) => {
        history.push(`/content/reviews/edit/${id}`);
    }
    return (
        <Switch>
            <Route path="/content/reviews/edit">
                <ReviewEditView theme={theme} />
            </Route>
            <Route path="/content/reviews/new">
                <ReviewEditView theme={theme} />
            </Route>
            <Route path="/content/reviews">
                <ReviewListView onEdit={onEditContent} theme={theme} />
            </Route>
        </Switch>
    )
}

export {ReviewListPage};